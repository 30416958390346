app.factory('Funcao', ['$http', function($http){
	return {
		getFuncoes: function() {
			return $http.get('/api/funcoes');
		},
		getFuncao: function(id) {
			return $http.get('/api/funcoes/' + id);	
		},
		insertFuncao: function (funcaoData) {
			return $http.post('/api/funcoes', funcaoData);
		},
		updateFuncao: function (funcaoData) {
			return $http.put('/api/funcoes/' + funcaoData.id, funcaoData);
		},
		deleteFuncao: function(id) {
			return $http.delete('/api/funcoes/' + id);
		}
	};
}])