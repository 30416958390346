app.controller('LancamentoListCtrl', ['$scope', 'ngDialog', 'Lancamento', 'toastr', 'SweetAlert', '$state', '$stateParams', 'Pessoa', 'Classificacao', 'Banco', 'Pessoa', 'Auth', function($scope, ngDialog, Lancamento, toastr, SweetAlert, $state, $stateParams, Pessoa, Classificacao, Banco, Pessoa, Auth) {
	$scope.lancamentos = [];
	$scope.totalLancamentos = 0;
	$scope.lancamentosPerPage = 10;
	$scope.searchText = $stateParams.searchText;
	$scope.sortType = $stateParams.sortType;
	$scope.sortReverse = $stateParams.sortReverse;
	$scope.pessoaId = $stateParams.pessoaId;
	$scope.token = Auth.getToken();
	$scope.selecionados = [];
	$scope.soma = 0.0;
	$scope.duplicateDialog = null;
	//pop-up de data ao duplicar lançamento
	$scope.popupDataLancamento = { opened: false, dateOptions: null };
	
	$scope.filtros = $stateParams.filtros || {
		'filtrar_por': 'data_emissao'
	};
	$scope.columns = $stateParams.columns || { // traz as colunas visiveis na tela de lançamentos, as true são as default
		'id': false,
		'nome': true,
		'data_emissao': false,
		'banco': false,
		'classificacao': true,
		'data_vencimento': true,
		'valor_liquido': true,
		'historico': false,
		'cheque': false,
		'data_pagamento': true,
		'documento': false,
		'forma_pagamento': false,
		'centro_custo': false
	};

    getClassificacaos();
	getBancos();
	getResultsPage(1, $scope.searchText, $scope.sortType, $scope.sortReverse, $scope.pessoaId, $scope.filtros);
	
	if ($scope.pessoaId) {
		Pessoa.getPessoa($scope.pessoaId)
			.then(function(response) {
				$scope.pessoa = response.data;
			});
	}

	$scope.pagination = {
	    current: 1
	};

	$scope.popupDataInicial = {
		opened: false
	};

	$scope.popupDataFinal = {
		opened: false
	};

	$scope.openDataInicial = function() {
		$scope.popupDataInicial.opened = true;
	}

	$scope.openDataFinal = function() {
		$scope.popupDataFinal.opened = true;
	}

	$scope.refreshLancamentos = function() {
		getResultsPage($scope.pagination.current, $scope.searchText, $scope.sortType, $scope.sortReverse, $scope.pessoaId, $scope.filtros);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.searchText, $scope.sortType, $scope.sortReverse, $scope.pessoaId, $scope.filtros);
	}

	$scope.changeSort = function(sortType, sortReverse) {
		$scope.sortType = sortType;
		$scope.sortReverse = sortReverse;
		$scope.refreshLancamentos();
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.deleteLancamento = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste lançamento não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {
				Lancamento.deleteLancamento(id)
					.then(function() {
						toastr.success('Lançamento removido com sucesso.');
						$scope.refreshLancamentos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este lançamento porque ele está vinculada em outros registros.');
					});
			}
		});
	}
	
	//modal com form para duplicar lançamentos
	$scope.duplicateLancamento = function(id){
		$scope.duplicateDialog = ngDialog.open({
			template: 'views/lancamentos/dialog_forms/duplicate.html', 
			className: 'ngdialog-theme-default', 
			showClose: false,
			scope: $scope,
			resolve: {
				id: function() {
					$scope.duplicateId = id;
					$scope.popupDataLancamento.opened = false;
					$scope.popupDataLancamento.dateOptions = { minDate: new Date() }
				}
			}
		});
	}
	//controle para mostrar a data de lançamento do registro duplicado
	$scope.openDataLancamento = function() {
		$scope.popupDataLancamento.opened = true;
	}
	//Envia requisição pra api para duplicar lançamentos
	$scope.duplicatePostings = function(id, duplicate){

		Lancamento.duplicateLancamento(id, duplicate.quantity, duplicate)
			.then(function(response) {
				closeDuplicatePostings();
				if (duplicate.quantity == 1) {
					$state.go('dashboard.lancamentos.edit', {
						'lancamentoId': response.data.id,
						'pessoaId': $stateParams.pessoaId,
						'searchText': $stateParams.searchText,
						'columns': $stateParams.columns,
						'sortType': $stateParams.sortType,
						'sortReverse': $stateParams.sortReverse,
						'filtros': $stateParams.filtros
					});
				} else {
					$scope.refreshLancamentos();
				}

				toastr.success(duplicate.quantity > 1 ? 'Lançamentos duplicados com sucesso.' : 'Lançamento duplicado com sucesso.');
			});
	}
	
	$scope.closeDuplicatePostings = function(){
		closeDuplicatePostings();
	}

	$scope.toggleColumn = function(column) {
		$scope.columns[column] = !$scope.columns[column];
	}

	$scope.numColunasAtivas = function() {
		var num = 0;
		angular.forEach($scope.columns, function(column) {
			if (column) {
				num++;
			}
		});
		return num;
	}


	$scope.foiSelecionado = function(id) {
		for (var i = 0; i < $scope.selecionados.length; i++) {
			if ($scope.selecionados[i].id == id) {
				return true;
			}
		}
		return false;
	}

	$scope.toggleSelecionado = function(lancamento) {
		var idx = -1;
		for (var i = 0; i < $scope.selecionados.length && idx == -1; i++) {
			if ($scope.selecionados[i].id == lancamento.id) {
				idx = i;
			}
		}
		if (idx >= 0) {
			$scope.selecionados.splice(idx, 1);
		} else {
			$scope.selecionados.push(lancamento);
		}
	}

	$scope.creditosSelecionados = function() {
		var soma = 0;
		for (var i = 0; i < $scope.selecionados.length; i++) {
			if ($scope.selecionados[i].tipo == 'C') {
				soma += $scope.selecionados[i].valor_liquido;
			}
		}
		return soma;
	}

	$scope.debitosSelecionados = function() {
		var soma = 0;
		for (var i = 0; i < $scope.selecionados.length; i++) {
			if ($scope.selecionados[i].tipo == 'D') {
				soma += $scope.selecionados[i].valor_liquido;
			}
		}
		return soma;
	}

	function getClassificacaos(){
        Classificacao.getClassificacaos()
			.then(function(response) {
				$scope.classificacaos = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}
	
	function closeDuplicatePostings(){
		$scope.duplicateDialog.close();
	}

	function getResultsPage(pageNumber, searchText, sortType, sortReverse, pessoaId, filtros) {
		Lancamento.getLancamentos(pageNumber, searchText, sortType, sortReverse, pessoaId, filtros)
			.then(function(response) {
				$scope.lancamentos = response.data.items;
				$scope.totalLancamentos = response.data.count;
				$scope.saldo = response.data.saldo;
				$scope.creditos = response.data.creditos;
				$scope.debitos = response.data.debitos;
			});
	}
}]);