app.controller('AtendimentoListCtrl', ['$scope', 'Atendimento', 'toastr', 'SweetAlert', '$state', 'Pessoa', 'Status', 'Produto', 'Motivo', 'User', 'ModuloProduto', 'Prioridade', '$uibModal', '$stateParams', function($scope, Atendimento, toastr, SweetAlert, $state, Pessoa, Status, Produto, Motivo, User, ModuloProduto, Prioridade, $uibModal, $stateParams) {
	$scope.atendimentos = [];
	$scope.totalAtendimentos = 0;
	$scope.atendimentosPerPage = 10;
	$scope.searchText = '';
	$scope.filtros = $stateParams.filtros || {	
		sortType: 'atendimentos.data_abertura',
		sortReverse: true,
		pagination: {
			current: 1
		}
	};

	getStatuses();
	getProdutos();
	getMotivos();
	getUsers();
	getModulosProdutos();
	getPrioridades();

	$scope.popupDataAbertura = {
		opened: false
	};

	$scope.openDataAbertura = function() {
		$scope.popupDataAbertura.opened = true;
	}

	function getStatuses(){
		Status.getStatuses()
			.then(function(response) {
				$scope.statuses = response.data;
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	function getMotivos(){
		Motivo.getMotivos()
			.then(function(response) {
				$scope.motivos = response.data;
			});
	}

	function getUsers(){
		User.getUsers()
			.then(function(response) {
				$scope.users = response.data;
			});
	}

	function getModulosProdutos(){
		ModuloProduto.getModulosProdutos()
			.then(function(response) {
				$scope.modulos_produtos = response.data;
			});
	}

	function getPrioridades() {
		Prioridade.getPrioridades()
			.then(function(response) {
				$scope.prioridades = response.data;
			});
	}

	$scope.refreshAtendimentos = function() {
		getResultsPage($scope.filtros.pagination.current);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage);
	}

	$scope.changeSort = function(sortType, sortReverse) {
		$scope.filtros.sortType = sortType;
		$scope.filtros.sortReverse = sortReverse;
		$scope.refreshAtendimentos();
	}

	$scope.deleteAtendimento = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste atendimento não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Atendimento.deleteAtendimento(id)
					.then(function() {
						toastr.success('Atendimento removido com sucesso.');
						$scope.refreshAtendimentos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este atendimento porque ele está vinculada em outros registros.');
					});
			}
		});
	}

	$scope.abrirAtendimento = function(id) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/atendimentos/_modal.html',
			controller: 'AtendimentoModalCtrl',
			scope: $scope,
			resolve: {
				atendimentoId: id
			}
		})
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', null, true)
			.then(function(response) {
				var items = response.data.items;
				items.push({id: null, razao_social: '-- Nenhuma --'});
				$scope.pessoas = items;
			});
	}

	function getResultsPage(pageNumber) {
		Atendimento.getAtendimentos(pageNumber, $scope.filtros, $scope.filtros.sortType, $scope.filtros.sortReverse)
			.then(function(response) {
				$scope.atendimentos = response.data.items;
				$scope.totalAtendimentos = response.data.count;
			});
	}

	$scope.clickConferido = function(atendimentoId, conferido) {
		Atendimento.updateAtendimento({
			id: atendimentoId,
			conferido: conferido ? 1 : 0
		}).then(function() {
			toastr.success('Atendimento ' + atendimentoId + (conferido ? ' marcado' : ' desmarcado') + ' como Conferido');
		});
	}

	$scope.refreshAtendimentos();
}]);