app.controller('SetorListCtrl', ['$scope', 'Setor', 'toastr', 'SweetAlert', '$state', function($scope, Setor, toastr, SweetAlert, $state) {
	$scope.setores = [];
	$scope.setoresPerPage = 10;

	$scope.refreshSetores = function() {
		Setor.getSetores()
			.then(function(response) {
				$scope.setores = response.data;
			});
	}

	$scope.refreshSetores();

	$scope.deleteSetor = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste setor não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Setor.deleteSetor(id)
					.then(function() {
						toastr.success('Setor removido com sucesso.');
						$scope.refreshSetores();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este setor porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);