app.controller('HistoricoContatoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'historico_contato', '$uibModalInstance', 'SweetAlert', 'Auth', '$filter', 'vm', function($scope, $state, $stateParams, toastr, historico_contato, $uibModalInstance, SweetAlert, Auth, $filter, vm) {
	$scope.historico_contato = angular.copy(historico_contato, {});

	$scope.popupData = {
		opened: false
	};

	$scope.popupDataNovoContato = {
		opened: false
	};

	$scope.openData = function() {
		$scope.popupData.opened = true;
	}

	$scope.openDataNovoContato = function() {
		$scope.popupDataNovoContato.opened = true;
	}

    $scope.save = function() {
        $uibModalInstance.close($scope.historico_contato);
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }

	$scope.$on('modal.closing', function(e, reason, closed) {
		if (reason != 'cancel' && !closed) {
			e.preventDefault();
		}
	});

	$scope.preencherEmailTelefone = function() {
		if (!$scope.historico_contato.telefone && !$scope.historico_contato.email) {
			var contatos = $filter('filter')(vm.pessoa.contatos, {
				id: $scope.historico_contato.contato.id
			});
			if (contatos.length == 1) {
				$scope.historico_contato.telefone = contatos[0]['telefone'];
				$scope.historico_contato.email = contatos[0]['email'];
			}
		}
	}

	$scope.addProduto = function() {
		$scope.historico_contato.produtos_ids.push($scope.historico_contato.produto.id);
		$scope.historico_contato.produto = null;
	}

	$scope.deleteProduto = function(index) {
		$scope.historico_contato.produtos_ids.splice(index, 1);
	}

	$scope.produtosDisponiveis = function(produtos) {
		var produtosDisponiveis = [];

		angular.forEach(produtos, function(produto) {
			if (!$scope.historico_contato.produtos_ids ||
				($scope.historico_contato.produtos_ids.indexOf(produto.id) < 0 &&
				$scope.historico_contato.produtos_ids.indexOf('' + produto.id + '') < 0)) {
				produtosDisponiveis.push(produto);
			}
		});

		return produtosDisponiveis;
	}

	$scope.descricaoProduto = function(produtoId, produtos) {
		for (var i = 0; i < produtos.length; i++) {
			if (produtos[i].id == produtoId) {
				return produtos[i].nome_comercial;
			}
		}
		return null;
	}
}]);