app.controller('DespesaFixaEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'DespesaFixa', 'Classificacao', 'CentroCusto', 'Pessoa', 'FormaPagamento', function($scope, $state, $stateParams, toastr, DespesaFixa, Classificacao, CentroCusto, Pessoa, FormaPagamento) {
	var vm = this;
	vm.despesaFixaId = $stateParams.despesaFixaId;

    getClassificacaos();
	getCentrosCusto();
	getDespesaFixa();
	getFormasPagamento();

	function getClassificacaos(){
        Classificacao.getClassificacaos()
			.then(function(response) {
				vm.classificacaos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}

	$scope.preencherCentroCusto = function() {
		if (vm.despesaFixa.classificacao && vm.despesaFixa.classificacaos.centro_custo_id) {
			vm.despesaFixa.centro_custo_id = vm.despesaFixa.classificacaos.centro_custo_id;
		}
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	function getDespesaFixa() {
		DespesaFixa.getDespesaFixa(vm.despesaFixaId)
			.success(function(response) {
				vm.despesaFixa = response;
			});
	}

	$scope.updateDespesaFixa = function() {
		DespesaFixa.updateDespesaFixa(vm.despesaFixa)
			.then(function() {
				toastr.success('Despesa fixa alterada com sucesso.');
				$state.go('dashboard.despesas_fixas.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.despesaFixaForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);