app.controller('ErroEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Erro', function($scope, $state, $stateParams, toastr, Erro) {
	var vm = this;
	vm.erroId = $stateParams.erroId;

	getErro();

	function getErro() {
		Erro.getErro(vm.erroId)
			.success(function(response) {
				vm.erro = response;
			});
	}

	$scope.updateErro = function() {
		Erro.updateErro(vm.erro)
			.then(function() {
				toastr.success('Erro alterado com sucesso.');
				$state.go('dashboard.erros.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.erroForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);