app.factory('Motivo', ['$http', function($http){
	return {
		getMotivos: function() {
			return $http.get('/api/motivos');
		},
		getMotivo: function(id) {
			return $http.get('/api/motivos/' + id);	
		},
		insertMotivo: function (motivoData) {
			return $http.post('/api/motivos', motivoData);
		},
		updateMotivo: function (motivoData) {
			return $http.put('/api/motivos/' + motivoData.id, motivoData);
		},
		deleteMotivo: function(id) {
			return $http.delete('/api/motivos/' + id);
		}
	};
}])