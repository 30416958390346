app.controller('CentroCustoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'CentroCusto', function($scope, $state, $stateParams, toastr, CentroCusto) {
	var vm = this;

	$scope.insertCentroCusto = function() {
		CentroCusto.insertCentroCusto(vm.centroCusto)
			.then(function() {
				toastr.success('Centro de custo salvo com sucesso.');
				$state.go('dashboard.centros_custo.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.centroCustoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);