var app = angular.module('adminxApp', [
		'ngAnimate',
		'ngMessages',
		'ngSanitize',
		'ngDialog',
		'ui.router',
		'ui.utils.masks',
		'ui.bootstrap',
		'idf.br-filters',
		'toastr',
		'angularUtils.directives.dirPagination',
		'oitozero.ngSweetAlert',
		'ncy-angular-breadcrumb',
		'ui.select',
		'checklist-model',
		'ivh.treeview',
		'textAngular'
	])
	.constant('AUTH_EVENTS', {
		loginSuccess: 'auth-login-success',
		loginFailed: 'auth-login-failed',
		logoutSuccess: 'auth-logout-success',
		sessionTimeout: 'auth-session-timeout',
		notAuthenticated: 'auth-not-authenticated',
		notAuthorized: 'auth-not-authorized'
	})
	.constant('STATUS_ATIVO', 1)
	.constant('STATUS_PENDENTE', 2)
	.constant('STATUS_FINALIZADO', 3)
	.constant('STATUS_EM_ANALISE', 4)
	.constant('STATUS_EM_DESENVOLVIMENTO', 5)
	.constant('STATUS_CANCELADA', 6)
	.constant('ROLE_ADMINISTRADOR', 1)
	.constant('ROLE_PROGRAMADOR', 2)
	.run(['$rootScope', '$state', 'Auth', 'toastr', 'ROLE_ADMINISTRADOR', function($rootScope, $state, Auth, toastr, ROLE_ADMINISTRADOR){
		$rootScope.isActive = function(roots) {
			if (!angular.isArray(roots)) {
				roots = [roots];
			}
			for (var i = roots.length - 1; i >= 0; i--) {
				if ($state.includes(roots[i])) {
					return true;
				}
			}
		}

		$rootScope.hasPermission = function(permissions) {
			if (!angular.isArray(permissions)) {
				permissions = [permissions];
			}
			for (var i = permissions.length - 1; i >= 0; i--) {
				if (Auth.hasPermission(permissions[i])) {
					return true;
				}
			}
		}

		$rootScope.ehAdministrador = function() {
			var currentUser = Auth.getCurrentUser();
			return currentUser.role_id == ROLE_ADMINISTRADOR;
		}

		$rootScope.hasntPermission = function(permissions) {
			return !$rootScope.hasPermission(permissions);
		}

		$rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options){
			if (!Auth.isAuthenticated() && (toState.auth || toState.auth == undefined)) {
				event.preventDefault();
				if (toState.name != 'dashboard.home') {
					toastr.error('Acesso negado.', 'Ops!');
				}
				$state.go('login');
			}

			if (toState.permission && !Auth.hasPermission(toState.permission)) {
				event.preventDefault();
				toastr.error('Acesso negado.', 'Ops!');
				$state.go('dashboard.home');
			}

			if (Auth.isAuthenticated() && !toState.auth) {
				event.preventDefault();
				toastr.error('Acesso negado.', 'Ops!');
				$state.go('dashboard.home');
			}
		});
	}])
	.config(['$stateProvider', '$urlRouterProvider', 'paginationTemplateProvider', '$httpProvider', 'ivhTreeviewOptionsProvider', '$provide', function($stateProvider, $urlRouterProvider, paginationTemplateProvider, $httpProvider, ivhTreeviewOptionsProvider, $provide) {
		paginationTemplateProvider.setPath('/views/dirPagination.tpl.html');

		$provide.decorator('taOptions', ['$delegate', function(taOptions) {
			taOptions.toolbar = [
				['p', 'pre', 'quote'],
				['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
				['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent']
			];
			taOptions.classes.toolbarButton = 'btn btn-default btn-sm';
			return taOptions;
		}]);

		$provide.decorator('uibDatepickerPopupDirective', function ($delegate) {
			var directive = $delegate[0];
			var link = directive.link;

			directive.compile = function () {
				return function (scope, element, attrs) {
					link.apply(this, arguments);
					element.mask("99/99/9999");
				};
			};

			return $delegate;
		});

		$httpProvider.interceptors.push('AuthInterceptor');

		$urlRouterProvider.otherwise('/home');

		$stateProvider
			.state('dashboard', {
				url: '',
				templateUrl: 'views/dashboard.html',
				abstract: true
			})
			.state('dashboard.pessoas', {
				abstract: true,
				url: '/pessoas',
				template: '<ui-view/>',
			})
			.state('dashboard.pessoas.list', {
				url: '',
				templateUrl: 'views/pessoas/index.html',
				controller: 'PessoaListCtrl',
				ncyBreadcrumb: {
					label: 'Pessoas'
				},
				auth: true,
				permission: 'pessoas.list'
			})
			.state('dashboard.pessoas.new', {
				url: '/new',
				templateUrl: 'views/pessoas/new.html',
				controller: 'PessoaNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Pessoa',
					parent: 'dashboard.pessoas.list'
				},
				auth: true,
				permission: 'pessoas.new'
			})
			.state('dashboard.pessoas.edit', {
				url: '/:pessoaId/edit',
				templateUrl: 'views/pessoas/edit.html',
				controller: 'PessoaEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Pessoa (ID: {{vm.pessoa.id}})',
					parent: 'dashboard.pessoas.list'
				},
				auth: true,
				permission: 'pessoas.edit'
			})
			.state('dashboard.contas', {
				abstract: true,
				url: '/contas',
				template: '<ui-view/>',
			})
			.state('dashboard.contas.list', {
				url: '',
				templateUrl: 'views/contas/index.html',
				controller: 'ContaListCtrl',
				ncyBreadcrumb: {
					label: 'Contas'
				},
				auth: true,
				permission: 'contas.list'
			})
			.state('dashboard.contas.new', {
				url: '/new',
				templateUrl: 'views/contas/new.html',
				controller: 'ContaNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Conta',
					parent: 'dashboard.contas.list'
				},
				auth: true,
				permission: 'contas.new'
			})
			.state('dashboard.contas.edit', {
				url: '/:contaId/edit',
				templateUrl: 'views/contas/edit.html',
				controller: 'ContaEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Conta (ID: {{vm.conta.id}})',
					parent: 'dashboard.contas.list'
				},
				auth: true,
				permission: 'contas.edit'
			})
			.state('dashboard.lancamentos', {
				abstract: true,
				url: '/lancamentos',
				template: '<ui-view/>',
				params: {
					searchText: '',
					columns: null,
					sortType: 'lancamentos.data_emissao',
					sortReverse: true,
					filtros: null
				}
			})
			.state('dashboard.lancamentos.list', {
				url: '?pessoaId',
				templateUrl: 'views/lancamentos/index.html',
				controller: 'LancamentoListCtrl',
				ncyBreadcrumb: {
					label: 'Lançamentos'
				},
				auth: true,
				permission: 'lancamentos.list'
			})
			.state('dashboard.lancamentos.new', {
				url: '/new?pessoaId',
				templateUrl: 'views/lancamentos/new.html',
				controller: 'LancamentoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Lançamento',
					parent: 'dashboard.lancamentos.list'
				},
				auth: true,
				permission: 'lancamentos.new'
			})
			.state('dashboard.lancamentos.edit', {
				url: '/:lancamentoId/edit',
				templateUrl: 'views/lancamentos/edit.html',
				controller: 'LancamentoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Lançamento (ID: {{vm.lancamento.id}})',
					parent: 'dashboard.lancamentos.list'
				},
				auth: true,
				permission: 'lancamentos.edit'
			})
			.state('dashboard.lancamentos.lote', {
				url: '/lote',
				templateUrl: 'views/lancamentos/lote.html',
				controller: 'LancamentoEmLoteCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Lançamento em Lote',
					parent: 'dashboard.lancamentos.list'
				},
				auth: true,
				permission: 'lancamentos.edit'
			})
			//------------
			.state('dashboard.origem_transacao', {
				abstract: true,
				url: '/origem_transacao',
				template: '<ui-view/>'
			})
			.state('dashboard.origem_transacao.list', {
				url: '',
				templateUrl: 'views/origem_transacao/index.html',
				controller: 'OrigemTransacaoListCtrl',
				ncyBreadcrumb: {
					label: 'Origens das Transações'
				},
				auth: true,
				permission: 'origem_transacao.list'
			})
			.state('dashboard.origem_transacao.new', {
				url: '/new',
				templateUrl: 'views/origem_transacao/new.html',
				controller: 'OrigemTransacaoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Origem',
					parent: 'dashboard.origem_transacao.list'
				},
				auth: true,
				permission: 'origem_transacao.new'
			})
			.state('dashboard.origem_transacao.edit', {
				url: '/:origemId/edit',
				templateUrl: 'views/origem_transacao/edit.html',
				controller: 'OrigemTransacaoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Origem (ID: {{vm.origem.id}})',
					parent: 'dashboard.origem_transacao.list'
				},
				auth: true,
				permission: 'origem_transacao.edit'
			})
			//------------
			.state('dashboard.atendimentos', {
				abstract: true,
				url: '/atendimentos',
				template: '<ui-view/>',
				params: {
					filtros: null
				}
			})
			.state('dashboard.atendimentos.list', {
				url: '',
				templateUrl: 'views/atendimentos/index.html',
				controller: 'AtendimentoListCtrl',
				ncyBreadcrumb: {
					label: 'Atendimentos'
				},
				auth: true,
				permission: 'atendimentos.list'
			})
			.state('dashboard.atendimentos.new', {
				url: '/new?pessoaId',
				templateUrl: 'views/atendimentos/new.html',
				controller: 'AtendimentoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Atendimento',
					parent: 'dashboard.atendimentos.list'
				},
				auth: true,
				permission: 'atendimentos.new'
			})
			.state('dashboard.atendimentos.edit', {
				url: '/:atendimentoId/edit',
				templateUrl: 'views/atendimentos/edit.html',
				controller: 'AtendimentoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Atendimento (ID: {{vm.atendimento.id}})',
					parent: 'dashboard.atendimentos.list'
				},
				auth: true,
				permission: 'atendimentos.edit'
			})
			.state('dashboard.classificacaos', {
				abstract: true,
				url: '/classificacao',
				template: '<ui-view/>'
			})
			.state('dashboard.classificacaos.list', {
				url: '',
				templateUrl: 'views/classificacao/index.html',
				controller: 'ClassificacaoListCtrl',
				ncyBreadcrumb: {
					label: 'Classificações'
				},
				auth: true,
				permission: 'classificacaos.list'
			})
			.state('dashboard.classificacaos.new', {
				url: '/new',
				templateUrl: 'views/classificacao/new.html',
				controller: 'ClassificacaoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Classificação',
					parent: 'dashboard.classificacaos.list'
				},
				auth: true,
				permission: 'classificacaos.new'
			})
			.state('dashboard.classificacaos.edit', {
				url: '/:classificacaoId/edit',
				templateUrl: 'views/classificacao/edit.html',
				controller: 'ClassificacaoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Classificações (ID: {{vm.classificacaos.id}})',
					parent: 'dashboard.classificacaos.list'
				},
				auth: true,
				permission: 'classificacaos.edit'
			})
			.state('dashboard.divisoes', {
				abstract: true,
				url: '/divisoes',
				template: '<ui-view/>'
			})
			.state('dashboard.divisoes.list', {
				url: '',
				templateUrl: 'views/divisoes/index.html',
				controller: 'DivisaoListCtrl',
				ncyBreadcrumb: {
					label: 'Divisões'
				},
				auth: true,
				permission: 'divisoes.list'
			})
			.state('dashboard.divisoes.new', {
				url: '/new',
				templateUrl: 'views/divisoes/new.html',
				controller: 'DivisaoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Divisão',
					parent: 'dashboard.divisoes.list'
				},
				auth: true,
				permission: 'divisoes.new'
			})
			.state('dashboard.divisoes.edit', {
				url: '/:divisaoId/edit',
				templateUrl: 'views/divisoes/edit.html',
				controller: 'DivisaoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Divisão (ID: {{vm.divisao.id}})',
					parent: 'dashboard.divisoes.list'
				},
				auth: true,
				permission: 'divisoes.edit'
			})
			.state('dashboard.setores', {
				abstract: true,
				url: '/setores',
				template: '<ui-view/>'
			})
			.state('dashboard.setores.list', {
				url: '',
				templateUrl: 'views/setores/index.html',
				controller: 'SetorListCtrl',
				ncyBreadcrumb: {
					label: 'Setores'
				},
				auth: true,
				permission: 'setores.list'
			})
			.state('dashboard.setores.new', {
				url: '/new',
				templateUrl: 'views/setores/new.html',
				controller: 'SetorNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Setor',
					parent: 'dashboard.setores.list'
				},
				auth: true,
				permission: 'setores.new'
			})
			.state('dashboard.setores.edit', {
				url: '/:setorId/edit',
				templateUrl: 'views/setores/edit.html',
				controller: 'SetorEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Setor (ID: {{vm.setor.id}})',
					parent: 'dashboard.setores.list'
				},
				auth: true,
				permission: 'setores.edit'
			})
			.state('dashboard.cargos', {
				abstract: true,
				url: '/cargos',
				template: '<ui-view/>'
			})
			.state('dashboard.cargos.list', {
				url: '',
				templateUrl: 'views/cargos/index.html',
				controller: 'CargoListCtrl',
				ncyBreadcrumb: {
					label: 'Cargos'
				},
				auth: true,
				permission: 'cargos.list'
			})
			.state('dashboard.cargos.new', {
				url: '/new',
				templateUrl: 'views/cargos/new.html',
				controller: 'CargoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Cargo',
					parent: 'dashboard.cargos.list'
				},
				auth: true,
				permission: 'cargos.new'
			})
			.state('dashboard.cargos.edit', {
				url: '/:cargoId/edit',
				templateUrl: 'views/cargos/edit.html',
				controller: 'CargoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Cargo (ID: {{vm.cargo.id}})',
					parent: 'dashboard.cargos.list'
				},
				auth: true,
				permission: 'cargos.edit'
			})
			.state('dashboard.bancos', {
				abstract: true,
				url: '/bancos',
				template: '<ui-view/>'
			})
			.state('dashboard.bancos.list', {
				url: '',
				templateUrl: 'views/bancos/index.html',
				controller: 'BancoListCtrl',
				ncyBreadcrumb: {
					label: 'Bancos'
				},
				auth: true,
				permission: 'bancos.list'
			})
			.state('dashboard.bancos.new', {
				url: '/new',
				templateUrl: 'views/bancos/new.html',
				controller: 'BancoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Banco',
					parent: 'dashboard.bancos.list'
				},
				auth: true,
				permission: 'bancos.new'
			})
			.state('dashboard.bancos.edit', {
				url: '/:bancoId/edit',
				templateUrl: 'views/bancos/edit.html',
				controller: 'BancoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Banco (ID: {{vm.banco.id}})',
					parent: 'dashboard.bancos.list'
				},
				auth: true,
				permission: 'bancos.edit'
			})
			.state('dashboard.profissoes', {
				abstract: true,
				url: '/profissoes',
				template: '<ui-view/>'
			})
			.state('dashboard.profissoes.list', {
				url: '',
				templateUrl: 'views/profissoes/index.html',
				controller: 'ProfissaoListCtrl',
				ncyBreadcrumb: {
					label: 'Profissões'
				},
				auth: true,
				permission: 'profissoes.list'
			})
			.state('dashboard.profissoes.new', {
				url: '/new',
				templateUrl: 'views/profissoes/new.html',
				controller: 'ProfissaoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Profissão',
					parent: 'dashboard.profissoes.list'
				},
				auth: true,
				permission: 'profissoes.new'
			})
			.state('dashboard.profissoes.edit', {
				url: '/:profissaoId/edit',
				templateUrl: 'views/profissoes/edit.html',
				controller: 'ProfissaoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Profissão (ID: {{vm.profissao.id}})',
					parent: 'dashboard.profissoes.list'
				},
				auth: true,
				permission: 'profissoes.edit'
			})
			.state('dashboard.statuses', {
				abstract: true,
				url: '/statuses',
				template: '<ui-view/>'
			})
			.state('dashboard.statuses.list', {
				url: '',
				templateUrl: 'views/statuses/index.html',
				controller: 'StatusListCtrl',
				ncyBreadcrumb: {
					label: 'Status'
				},
				auth: true,
				permission: 'statuses.list'
			})
			.state('dashboard.statuses.new', {
				url: '/new',
				templateUrl: 'views/statuses/new.html',
				controller: 'StatusNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Status',
					parent: 'dashboard.statuses.list'
				},
				auth: true,
				permission: 'statuses.new'
			})
			.state('dashboard.statuses.edit', {
				url: '/:statusId/edit',
				templateUrl: 'views/statuses/edit.html',
				controller: 'StatusEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Status (ID: {{vm.status.id}})',
					parent: 'dashboard.statuses.list'
				},
				auth: true,
				permission: 'statuses.edit'
			})
			.state('dashboard.erros', {
				abstract: true,
				url: '/erros',
				template: '<ui-view/>'
			})
			.state('dashboard.erros.list', {
				url: '',
				templateUrl: 'views/erros/index.html',
				controller: 'ErroListCtrl',
				ncyBreadcrumb: {
					label: 'Erros'
				},
				auth: true,
				permission: 'erros.list'
			})
			.state('dashboard.erros.new', {
				url: '/new',
				templateUrl: 'views/erros/new.html',
				controller: 'ErroNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Erro',
					parent: 'dashboard.erros.list'
				},
				auth: true,
				permission: 'erros.new'
			})
			.state('dashboard.erros.edit', {
				url: '/:erroId/edit',
				templateUrl: 'views/erros/edit.html',
				controller: 'ErroEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Erro (ID: {{vm.erro.id}})',
					parent: 'dashboard.erros.list'
				},
				auth: true,
				permission: 'erros.edit'
			})
			.state('dashboard.planos', {
				abstract: true,
				url: '/planos',
				template: '<ui-view/>'
			})
			.state('dashboard.planos.list', {
				url: '',
				templateUrl: 'views/planos/index.html',
				controller: 'PlanoListCtrl',
				ncyBreadcrumb: {
					label: 'Planos'
				},
				auth: true,
				permission: 'planos.list'
			})
			.state('dashboard.planos.new', {
				url: '/new',
				templateUrl: 'views/planos/new.html',
				controller: 'PlanoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Plano',
					parent: 'dashboard.planos.list'
				},
				auth: true,
				permission: 'planos.new'
			})
			.state('dashboard.planos.edit', {
				url: '/:planoId/edit',
				templateUrl: 'views/planos/edit.html',
				controller: 'PlanoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Plano (ID: {{vm.plano.id}})',
					parent: 'dashboard.planos.list'
				},
				auth: true,
				permission: 'planos.edit'
			})
			.state('dashboard.motivos', {
				abstract: true,
				url: '/motivos',
				template: '<ui-view/>'
			})
			.state('dashboard.motivos.list', {
				url: '',
				templateUrl: 'views/motivos/index.html',
				controller: 'MotivoListCtrl',
				ncyBreadcrumb: {
					label: 'Motivos'
				},
				auth: true,
				permission: 'motivos.list'
			})
			.state('dashboard.motivos.new', {
				url: '/new',
				templateUrl: 'views/motivos/new.html',
				controller: 'MotivoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Motivo',
					parent: 'dashboard.motivos.list'
				},
				auth: true,
				permission: 'motivos.new'
			})
			.state('dashboard.motivos.edit', {
				url: '/:motivoId/edit',
				templateUrl: 'views/motivos/edit.html',
				controller: 'MotivoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Motivo (ID: {{vm.motivo.id}})',
					parent: 'dashboard.motivos.list'
				},
				auth: true,
				permission: 'motivos.edit'
			})
			.state('dashboard.brindes', {
				abstract: true,
				url: '/brindes',
				template: '<ui-view/>'
			})
			.state('dashboard.brindes.list', {
				url: '',
				templateUrl: 'views/brindes/index.html',
				controller: 'BrindeListCtrl',
				ncyBreadcrumb: {
					label: 'Controle de Brindes'
				},
				auth: true,
				permission: 'brindes.list'
			})
			.state('dashboard.brindes.new', {
				url: '/new',
				templateUrl: 'views/brindes/new.html',
				controller: 'BrindeNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Brinde',
					parent: 'dashboard.brindes.list'
				},
				auth: true,
				permission: 'brindes.new'
			})
			.state('dashboard.brindes.edit', {
				url: '/:brindeId/edit',
				templateUrl: 'views/brindes/edit.html',
				controller: 'BrindeEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Brinde (ID: {{vm.brinde.id}})',
					parent: 'dashboard.brindes.list'
				},
				auth: true,
				permission: 'brindes.edit'
			})
			.state('dashboard.produtos', {
				abstract: true,
				url: '/produtos',
				template: '<ui-view/>'
			})
			.state('dashboard.produtos.list', {
				url: '',
				templateUrl: 'views/produtos/index.html',
				controller: 'ProdutoListCtrl',
				ncyBreadcrumb: {
					label: 'Produtos'
				},
				auth: true,
				permission: 'produtos.list'
			})
			.state('dashboard.produtos.new', {
				url: '/new',
				templateUrl: 'views/produtos/new.html',
				controller: 'ProdutoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Produto',
					parent: 'dashboard.produtos.list'
				},
				auth: true,
				permission: 'produtos.new'
			})
			.state('dashboard.produtos.edit', {
				url: '/:produtoId/edit',
				templateUrl: 'views/produtos/edit.html',
				controller: 'ProdutoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Produto (ID: {{vm.produto.id}})',
					parent: 'dashboard.produtos.list'
				},
				auth: true,
				permission: 'produtos.edit'
			})
			.state('dashboard.contratos', {
				abstract: true,
				url: '/contratos',
				template: '<ui-view/>'
			})
			.state('dashboard.contratos.list', {
				url: '',
				templateUrl: 'views/contratos/index.html',
				controller: 'ContratoListCtrl',
				ncyBreadcrumb: {
					label: 'Contratos'
				},
				auth: true,
				permission: 'contratos.list'
			})
			.state('dashboard.contratos.new', {
				url: '/new',
				templateUrl: 'views/contratos/new.html',
				controller: 'ContratoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Contrato',
					parent: 'dashboard.contratos.list'
				},
				auth: true,
				permission: 'contratos.new'
			})
			.state('dashboard.contratos.edit', {
				url: '/:contratoId/edit',
				templateUrl: 'views/contratos/edit.html',
				controller: 'ContratoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Contrato (ID: {{vm.contrato.id}})',
					parent: 'dashboard.contratos.list'
				},
				auth: true,
				permission: 'contratos.edit'
			})
			.state('dashboard.origens', {
				abstract: true,
				url: '/origens',
				template: '<ui-view/>'
			})
			.state('dashboard.origens.list', {
				url: '',
				templateUrl: 'views/origens/index.html',
				controller: 'OrigemListCtrl',
				ncyBreadcrumb: {
					label: 'Origens'
				},
				auth: true,
				permission: 'origens.list'
			})
			.state('dashboard.origens.new', {
				url: '/new',
				templateUrl: 'views/origens/new.html',
				controller: 'OrigemNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Origem',
					parent: 'dashboard.origens.list'
				},
				auth: true,
				permission: 'origens.new'
			})
			.state('dashboard.origens.edit', {
				url: '/:origemId/edit',
				templateUrl: 'views/origens/edit.html',
				controller: 'OrigemEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Origem (ID: {{vm.origem.id}})',
					parent: 'dashboard.origens.list'
				},
				auth: true,
				permission: 'origens.edit'
			})
			.state('dashboard.mensagens', {
				abstract: true,
				url: '/mensagens',
				template: '<ui-view/>'
			})
			.state('dashboard.mensagens.list', {
				url: '',
				templateUrl: 'views/mensagens/index.html',
				controller: 'MensagemListCtrl',
				ncyBreadcrumb: {
					label: 'Mensagens'
				},
				auth: true,
				permission: 'mensagens.list'
			})
			.state('dashboard.mensagens.new', {
				url: '/new',
				templateUrl: 'views/mensagens/new.html',
				controller: 'MensagemNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Mensagem',
					parent: 'dashboard.mensagens.list'
				},
				auth: true,
				permission: 'mensagens.new'
			})
			.state('dashboard.mensagens.edit', {
				url: '/:mensagemId/edit',
				templateUrl: 'views/mensagens/edit.html',
				controller: 'MensagemEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Mensagem (ID: {{vm.mensagem.id}})',
					parent: 'dashboard.mensagens.list'
				},
				auth: true,
				permission: 'mensagens.edit'
			})
			.state('dashboard.prioridades', {
				abstract: true,
				url: '/prioridades',
				template: '<ui-view/>'
			})
			.state('dashboard.prioridades.list', {
				url: '',
				templateUrl: 'views/prioridades/index.html',
				controller: 'PrioridadeListCtrl',
				ncyBreadcrumb: {
					label: 'Prioridades'
				},
				auth: true,
				permission: 'prioridades.list'
			})
			.state('dashboard.prioridades.new', {
				url: '/new',
				templateUrl: 'views/prioridades/new.html',
				controller: 'PrioridadeNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Prioridade',
					parent: 'dashboard.prioridades.list'
				},
				auth: true,
				permission: 'prioridades.new'
			})
			.state('dashboard.prioridades.edit', {
				url: '/:prioridadeId/edit',
				templateUrl: 'views/prioridades/edit.html',
				controller: 'PrioridadeEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Prioridade (ID: {{vm.prioridade.id}})',
					parent: 'dashboard.prioridades.list'
				},
				auth: true,
				permission: 'prioridades.edit'
			})
			.state('dashboard.funcoes', {
				abstract: true,
				url: '/funcoes',
				template: '<ui-view/>'
			})
			.state('dashboard.funcoes.list', {
				url: '',
				templateUrl: 'views/funcoes/index.html',
				controller: 'FuncaoListCtrl',
				ncyBreadcrumb: {
					label: 'Funções'
				},
				auth: true,
				permission: 'funcoes.list'
			})
			.state('dashboard.funcoes.new', {
				url: '/new',
				templateUrl: 'views/funcoes/new.html',
				controller: 'FuncaoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Função',
					parent: 'dashboard.funcoes.list'
				},
				auth: true,
				permission: 'funcoes.new'
			})
			.state('dashboard.funcoes.edit', {
				url: '/:funcaoId/edit',
				templateUrl: 'views/funcoes/edit.html',
				controller: 'FuncaoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Função (ID: {{vm.funcao.id}})',
					parent: 'dashboard.funcoes.list'
				},
				auth: true,
				permission: 'funcoes.edit'
			})
			.state('dashboard.tipos', {
				abstract: true,
				url: '/tipos',
				template: '<ui-view/>'
			})
			.state('dashboard.tipos.list', {
				url: '',
				templateUrl: 'views/tipos/index.html',
				controller: 'TipoListCtrl',
				ncyBreadcrumb: {
					label: 'Tipos de Pessoa'
				},
				auth: true,
				permission: 'tipos.list'
			})
			.state('dashboard.tipos.new', {
				url: '/new',
				templateUrl: 'views/tipos/new.html',
				controller: 'TipoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Tipo de Pessoa',
					parent: 'dashboard.tipos.list'
				},
				auth: true,
				permission: 'tipos.new'
			})
			.state('dashboard.tipos.edit', {
				url: '/:tipoId/edit',
				templateUrl: 'views/tipos/edit.html',
				controller: 'TipoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Tipo de Pessoa (ID: {{vm.tipo.id}})',
					parent: 'dashboard.tipos.list'
				},
				auth: true,
				permission: 'tipos.edit'
			})
			.state('dashboard.centros_custo', {
				abstract: true,
				url: '/centros-custo',
				template: '<ui-view/>'
			})
			.state('dashboard.centros_custo.list', {
				url: '',
				templateUrl: 'views/centros_custo/index.html',
				controller: 'CentroCustoListCtrl',
				ncyBreadcrumb: {
					label: 'Centros de Custo'
				},
				auth: true,
				permission: 'centros_custo.list'
			})
			.state('dashboard.centros_custo.new', {
				url: '/new',
				templateUrl: 'views/centros_custo/new.html',
				controller: 'CentroCustoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Centro de Custo',
					parent: 'dashboard.centros_custo.list'
				},
				auth: true,
				permission: 'centros_custo.new'
			})
			.state('dashboard.centros_custo.edit', {
				url: '/:centroCustoId/edit',
				templateUrl: 'views/centros_custo/edit.html',
				controller: 'CentroCustoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Centro de Custo (ID: {{vm.centroCusto.id}})',
					parent: 'dashboard.centros_custo.list'
				},
				auth: true,
				permission: 'centros_custo.edit'
			})
			.state('dashboard.despesas_fixas', {
				abstract: true,
				url: '/despesas-fixas',
				template: '<ui-view/>'
			})
			.state('dashboard.despesas_fixas.list', {
				url: '',
				templateUrl: 'views/despesas_fixas/index.html',
				controller: 'DespesaFixaListCtrl',
				ncyBreadcrumb: {
					label: 'Despesas Fixas'
				},
				auth: true,
				permission: 'despesas_fixas.list'
			})
			.state('dashboard.despesas_fixas.new', {
				url: '/new',
				templateUrl: 'views/despesas_fixas/new.html',
				controller: 'DespesaFixaNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Despesa Fixa',
					parent: 'dashboard.despesas_fixas.list'
				},
				auth: true,
				permission: 'despesas_fixas.new'
			})
			.state('dashboard.despesas_fixas.edit', {
				url: '/:despesaFixaId/edit',
				templateUrl: 'views/despesas_fixas/edit.html',
				controller: 'DespesaFixaEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Despesa Fixa (ID: {{vm.despesaFixa.id}})',
					parent: 'dashboard.despesas_fixas.list'
				},
				auth: true,
				permission: 'despesas_fixas.edit'
			})
			.state('dashboard.tipos_contato', {
				abstract: true,
				url: '/tipos-contato',
				template: '<ui-view/>'
			})
			.state('dashboard.tipos_contato.list', {
				url: '',
				templateUrl: 'views/tipos_contato/index.html',
				controller: 'TipoContatoListCtrl',
				ncyBreadcrumb: {
					label: 'Tipos de Contato'
				},
				auth: true,
				permission: 'tipos_contato.list'
			})
			.state('dashboard.tipos_contato.new', {
				url: '/new',
				templateUrl: 'views/tipos_contato/new.html',
				controller: 'TipoContatoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Tipo de Contato',
					parent: 'dashboard.tipos_contato.list'
				},
				auth: true,
				permission: 'tipos_contato.new'
			})
			.state('dashboard.tipos_contato.edit', {
				url: '/:tipoContatoId/edit',
				templateUrl: 'views/tipos_contato/edit.html',
				controller: 'TipoContatoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Tipo de Contato (ID: {{vm.tipoContato.id}})',
					parent: 'dashboard.tipos_contato.list'
				},
				auth: true,
				permission: 'tipos_contato.edit'
			})
			.state('dashboard.ramos_atuacao', {
				abstract: true,
				url: '/ramos-atuacao',
				template: '<ui-view/>'
			})
			.state('dashboard.ramos_atuacao.list', {
				url: '',
				templateUrl: 'views/ramos_atuacao/index.html',
				controller: 'RamoAtuacaoListCtrl',
				ncyBreadcrumb: {
					label: 'Ramos de Atuação'
				},
				auth: true,
				permission: 'ramos_atuacao.list'
			})
			.state('dashboard.ramos_atuacao.new', {
				url: '/new',
				templateUrl: 'views/ramos_atuacao/new.html',
				controller: 'RamoAtuacaoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Ramo de Atuação',
					parent: 'dashboard.ramos_atuacao.list'
				},
				auth: true,
				permission: 'ramos_atuacao.new'
			})
			.state('dashboard.ramos_atuacao.edit', {
				url: '/:ramoAtuacaoId/edit',
				templateUrl: 'views/ramos_atuacao/edit.html',
				controller: 'RamoAtuacaoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Ramo de Atuação (ID: {{vm.ramoAtuacao.id}})',
					parent: 'dashboard.ramos_atuacao.list'
				},
				auth: true,
				permission: 'ramos_atuacao.edit'
			})
			.state('dashboard.formas_pagamento', {
				abstract: true,
				url: '/formas-pagamento',
				template: '<ui-view/>'
			})
			.state('dashboard.formas_pagamento.list', {
				url: '',
				templateUrl: 'views/formas_pagamento/index.html',
				controller: 'FormaPagamentoListCtrl',
				ncyBreadcrumb: {
					label: 'Formas de Pagamento'
				},
				auth: true,
				permission: 'formas_pagamento.list'
			})
			.state('dashboard.formas_pagamento.new', {
				url: '/new',
				templateUrl: 'views/formas_pagamento/new.html',
				controller: 'FormaPagamentoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Nova Forma de Pagamento',
					parent: 'dashboard.formas_pagamento.list'
				},
				auth: true,
				permission: 'formas_pagamento.new'
			})
			.state('dashboard.formas_pagamento.edit', {
				url: '/:formaPagamentoId/edit',
				templateUrl: 'views/formas_pagamento/edit.html',
				controller: 'FormaPagamentoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Forma de Pagamento (ID: {{vm.formaPagamento.id}})',
					parent: 'dashboard.formas_pagamento.list'
				},
				auth: true,
				permission: 'formas_pagamento.edit'
			})
			.state('dashboard.modulos_produtos', {
				abstract: true,
				url: '/modulos-produtos',
				template: '<ui-view/>'
			})
			.state('dashboard.modulos_produtos.list', {
				url: '',
				templateUrl: 'views/modulos_produtos/index.html',
				controller: 'ModuloProdutoListCtrl',
				ncyBreadcrumb: {
					label: 'Módulos dos Produtos'
				},
				auth: true,
				permission: 'modulos_produtos.list'
			})
			.state('dashboard.modulos_produtos.new', {
				url: '/new',
				templateUrl: 'views/modulos_produtos/new.html',
				controller: 'ModuloProdutoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Módulo de Produto',
					parent: 'dashboard.modulos_produtos.list'
				},
				auth: true,
				permission: 'modulos_produtos.new'
			})
			.state('dashboard.modulos_produtos.edit', {
				url: '/:moduloProdutoId/edit',
				templateUrl: 'views/modulos_produtos/edit.html',
				controller: 'ModuloProdutoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Módulo de Produto (ID: {{vm.moduloProduto.id}})',
					parent: 'dashboard.modulos_produtos.list'
				},
				auth: true,
				permission: 'modulos_produtos.edit'
			})
			.state('dashboard.tipos_relacionamento', {
				abstract: true,
				url: '/tipos-atuacao',
				template: '<ui-view/>'
			})
			.state('dashboard.tipos_relacionamento.list', {
				url: '',
				templateUrl: 'views/tipos_relacionamento/index.html',
				controller: 'TipoRelacionamentoListCtrl',
				ncyBreadcrumb: {
					label: 'Tipos de Relacionamento'
				},
				auth: true,
				permission: 'tipos_relacionamento.list'
			})
			.state('dashboard.tipos_relacionamento.new', {
				url: '/new',
				templateUrl: 'views/tipos_relacionamento/new.html',
				controller: 'TipoRelacionamentoNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Tipo de Relacionamento',
					parent: 'dashboard.tipos_relacionamento.list'
				},
				auth: true,
				permission: 'tipos_relacionamento.new'
			})
			.state('dashboard.tipos_relacionamento.edit', {
				url: '/:tipoRelacionamentoId/edit',
				templateUrl: 'views/tipos_relacionamento/edit.html',
				controller: 'TipoRelacionamentoEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Tipo de Relacionamento (ID: {{vm.tipoRelacionamento.id}})',
					parent: 'dashboard.tipos_relacionamento.list'
				},
				auth: true,
				permission: 'tipos_relacionamento.edit'
			})
			.state('dashboard.users', {
				abstract: true,
				url: '/users',
				template: '<ui-view/>'
			})
			.state('dashboard.users.list', {
				url: '',
				templateUrl: 'views/users/index.html',
				controller: 'UserListCtrl',
				ncyBreadcrumb: {
					label: 'Usuários'
				},
				auth: true,
				permission: 'users.list'
			})
			.state('dashboard.users.new', {
				url: '/new',
				templateUrl: 'views/users/new.html',
				controller: 'UserNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Usuário',
					parent: 'dashboard.users.list'
				},
				auth: true,
				permission: 'users.new'
			})
			.state('dashboard.users.edit', {
				url: '/:userId/edit',
				templateUrl: 'views/users/edit.html',
				controller: 'UserEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Usuário (ID: {{vm.user.id}})',
					parent: 'dashboard.users.list'
				},
				auth: true,
				permission: 'users.edit'
			})
			.state('dashboard.roles', {
				abstract: true,
				url: '/perfis',
				template: '<ui-view/>'
			})
			.state('dashboard.roles.list', {
				url: '',
				templateUrl: 'views/roles/index.html',
				controller: 'RoleListCtrl',
				ncyBreadcrumb: {
					label: 'Perfis'
				},
				auth: true,
				permission: 'roles.list'
			})
			.state('dashboard.roles.new', {
				url: '/new',
				templateUrl: 'views/roles/new.html',
				controller: 'RoleNewCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Novo Perfil',
					parent: 'dashboard.roles.list'
				},
				auth: true,
				permission: 'roles.new'
			})
			.state('dashboard.roles.edit', {
				url: '/:roleId/edit',
				templateUrl: 'views/roles/edit.html',
				controller: 'RoleEditCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Editar Perfil (ID: {{vm.role.id}})',
					parent: 'dashboard.roles.list'
				},
				auth: true,
				permission: 'roles.edit'
			})
			.state('dashboard.relatorios', {
				abstract: true,
				url: '/relatorios',
				template: '<ui-view/>'
			})
			.state('dashboard.relatorios.fluxo_financeiro', {
				url: '/fluxo-financeiro',
				templateUrl: 'views/relatorios/fluxo_financeiro.html',
				controller: 'FluxoFinanceiroCtrl',
				ncyBreadcrumb: {
					label: 'Fluxo Financeiro',
				},
				auth: true,
				permission: 'relatorios.fluxo_financeiro'
			})
			.state('dashboard.relatorios.producao', {
				url: '/producao',
				templateUrl: 'views/relatorios/producao.html',
				controller: 'ProducaoCtrl',
				ncyBreadcrumb: {
					label: 'Produção',
				},
				auth: true,
				permission: 'relatorios.producao'
			})
			.state('dashboard.relatorios.avaliacao_receitas_despesas_anual', {
				url: '/avaliacao-receitas-despesas-anual',
				templateUrl: 'views/relatorios/avaliacao_receitas_despesas_anual.html',
				controller: 'AvaliacaoReceitasDespesasAnualCtrl',
				ncyBreadcrumb: {
					label: 'Avaliação Receitas e Despesas Anual',
				},
				auth: true,
				permission: 'relatorios.avaliacao_receitas_despesas_anual'
			})
			.state('dashboard.relatorios.fluxo_caixa_futuro', {
				url: '/fluxo-caixa-futuro',
				templateUrl: 'views/relatorios/fluxo_caixa_futuro.html',
				controller: 'FluxoCaixaFuturoCtrl',
				ncyBreadcrumb: {
					label: 'Fluxo de Caixa Futuro',
				},
				auth: true,
				permission: 'relatorios.fluxo_caixa_futuro'
			})
			.state('dashboard.settings', {
				url: '/settings',
				templateUrl: 'views/settings.html',
				controller: 'SettingsCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Configurações'
				},
				auth: true
			})
			.state('dashboard.account', {
				url: '/account',
				templateUrl: 'views/account.html',
				controller: 'AccountCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Minha Conta'
				},
				auth: true
			})
			.state('dashboard.nfse', {
				url: '/nfse',
				templateUrl: 'views/nfse/emissao.html',
				controller: 'NfseCtrl',
				controllerAs: 'vm',
				ncyBreadcrumb: {
					label: 'Emitir NFS-e'
				},
				params: {
					lancamentoId: ''
				},
				auth: true
			})
			.state('dashboard.home', {
				url: '/home',
				templateUrl: 'views/home.html',
				controller: 'HomeCtrl',
				ncyBreadcrumb: {
					label: 'Início'
				},
				auth: true
			})
			.state('login', {
				url: '/login',
				templateUrl: 'views/login.html',
				controller: 'LoginCtrl',
				auth: false
			});

		ivhTreeviewOptionsProvider.set({
			twistieCollapsedTpl: '<span class="glyphicon glyphicon-chevron-right"></span>',
			twistieExpandedTpl: '<span class="glyphicon glyphicon-chevron-down"></span>',
			twistieLeafTpl: '&#9679;'
		});
	}]);
