app.controller('CargoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Cargo', function($scope, $state, $stateParams, toastr, Cargo) {
	var vm = this;

	$scope.insertCargo = function() {
		Cargo.insertCargo(vm.cargo)
			.then(function() {
				toastr.success('Cargo salvo com sucesso.');
				$state.go('dashboard.cargos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.cargoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);