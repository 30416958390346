app.controller('FuncaoListCtrl', ['$scope', 'Funcao', 'toastr', 'SweetAlert', '$state', function($scope, Funcao, toastr, SweetAlert, $state) {
	$scope.funcoes = [];
	$scope.funcoesPerPage = 10;

	$scope.refreshFuncoes = function() {
		Funcao.getFuncoes()
			.then(function(response) {
				$scope.funcoes = response.data;
			});
	}

	$scope.refreshFuncoes();

	$scope.deleteFuncao = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta função não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Funcao.deleteFuncao(id)
					.then(function() {
						toastr.success('Função removida com sucesso.');
						$scope.refreshFuncoes();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta função porque ela está vinculada em outros registros.');
					});
			}
		});
	}
}]);