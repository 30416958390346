app.controller('DespesaFixaListCtrl', ['$scope', 'DespesaFixa', 'toastr', 'SweetAlert', '$state', '$uibModal', function($scope, DespesaFixa, toastr, SweetAlert, $state, $uibModal) {
	$scope.despesasFixas = [];
	$scope.despesasFixasPerPage = 10;

	$scope.refreshDespesasFixas = function() {
		DespesaFixa.getDespesasFixas()
			.then(function(response) {
				$scope.despesasFixas = response.data;
			});
	}

	$scope.refreshDespesasFixas();

	$scope.deleteDespesaFixa = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta despesa não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				DespesaFixa.deleteDespesaFixa(id)
					.then(function() {
						toastr.success('Despesa removida com sucesso.');
						$scope.refreshDespesasFixas();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta despesa porque ela está vinculada em outros registros.');
					});
			}
		});
	}

	$scope.gerarRegistros = function() {
		SweetAlert.swal({
			title: '',
			text: 'Deseja Inserir os registros abaixo automaticamente no Módulo Financeiro? ',
			type: 'info',
			cancelButtonText: 'Não',
			confirmButtonColor: "#46B999",
			confirmButtonText: "Sim",
			showCancelButton: true,
			closeOnConfirm: true,
			closeOnCancel: true
		}, function(isConfirm) {
			if (!isConfirm) {
				return false;
			}

			var modalInstance = $uibModal.open({
				templateUrl: '/views/despesas_fixas/_mes.html',
				scope: $scope,
				controller: 'MonthPickerCtrl',
				size: 'sm'
			})

			modalInstance.result.then(function(resposta) {
				DespesaFixa.gerarRegistro(resposta.mes, resposta.banco)
					.then(function() {
						toastr.success('Registros gerados com sucesso.');
					})
					.catch(function() {
						toastr.error('Ocorreu um erro.');
					});
			});
		});
	}
}]);