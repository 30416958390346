app.controller('MensagemEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Mensagem', 'CentroCusto', function($scope, $state, $stateParams, toastr, Mensagem, CentroCusto) {
	var vm = this;
	vm.imagem = '';
	vm.mensagemId = $stateParams.mensagemId;

	getMensagem();

	function getMensagem() {
		Mensagem.getMensagem(vm.mensagemId)
			.success(function(response) {
				vm.mensagem = response;
			});
	}

	$scope.updateMensagem = function() {
		Mensagem.updateMensagem(vm.mensagem, vm.imagem)
			.then(function() {
				toastr.success('Mensagem alterada com sucesso.');
				$state.go('dashboard.mensagens.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.mensagemForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);