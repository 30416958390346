app.controller('AvaliarSolicitacaoCtrl', ['$scope', '$uibModalInstance', 'SweetAlert', 'solicitacao', function($scope, $uibModalInstance, SweetAlert, solicitacao) {
	$scope.solicitacao = solicitacao;

	$scope.start = function() {
        $uibModalInstance.close();
		$scope.idxIntervencao = $scope.iniciarIntervencao(solicitacao);
		$scope.finalizarIntervencao($scope.idxIntervencao);
	}

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }
}]);