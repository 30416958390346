app.controller('TipoContatoListCtrl', ['$scope', 'TipoContato', 'toastr', 'SweetAlert', '$state', function($scope, TipoContato, toastr, SweetAlert, $state) {
	$scope.tiposContato = [];
	$scope.tiposContatoPerPage = 10;

	$scope.refreshTiposContato = function() {
		TipoContato.getTiposContato()
			.then(function(response) {
				$scope.tiposContato = response.data;
			});
	}

	$scope.refreshTiposContato();

	$scope.deleteTipoContato = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste tipo de contato não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				TipoContato.deleteTipoContato(id)
					.then(function() {
						toastr.success('Tipo de contato removido com sucesso.');
						$scope.refreshTiposContato();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este tipo de contato porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);