app.controller('BancoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Banco', function($scope, $state, $stateParams, toastr, Banco) {
	var vm = this;
	vm.bancoId = $stateParams.bancoId;

	getBanco();

	function getBanco() {
		Banco.getBanco(vm.bancoId)
			.success(function(response) {
				vm.banco = response;
			});
	}

	$scope.updateBanco = function() {
		Banco.updateBanco(vm.banco)
			.then(function() {
				toastr.success('Banco alterado com sucesso.');
				$state.go('dashboard.bancos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.bancoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);