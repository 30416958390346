app.factory('Tipo', ['$http', function($http){
	return {
		getTipos: function() {
			return $http.get('/api/tipos');
		},
		getTipo: function(id) {
			return $http.get('/api/tipos/' + id);	
		},
		insertTipo: function (tipoData) {
			return $http.post('/api/tipos', tipoData);
		},
		updateTipo: function (tipoData) {
			return $http.put('/api/tipos/' + tipoData.id, tipoData);
		},
		deleteTipo: function(id) {
			return $http.delete('/api/tipos/' + id);
		}
	};
}])