app.controller('RamoAtuacaoListCtrl', ['$scope', 'RamoAtuacao', 'toastr', 'SweetAlert', '$state', function($scope, RamoAtuacao, toastr, SweetAlert, $state) {
	$scope.ramosAtuacao = [];
	$scope.ramosAtuacaoPerPage = 10;

	$scope.refreshRamosAtuacao = function() {
		RamoAtuacao.getRamosAtuacao()
			.then(function(response) {
				$scope.ramosAtuacao = response.data;
			});
	}

	$scope.refreshRamosAtuacao();

	$scope.deleteRamoAtuacao = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste ramo de atuação não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				RamoAtuacao.deleteRamoAtuacao(id)
					.then(function() {
						toastr.success('Ramo de atuação removido com sucesso.');
						$scope.refreshRamosAtuacao();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este ramo de atuação porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);