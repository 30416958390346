app.factory('CentroCusto', ['$http', function($http){
	return {
		getCentrosCusto: function() {
			return $http.get('/api/centros-custo');
		},
		getCentroCusto: function(id) {
			return $http.get('/api/centros-custo/' + id);	
		},
		insertCentroCusto: function (centroCustoData) {
			return $http.post('/api/centros-custo', centroCustoData);
		},
		updateCentroCusto: function (centroCustoData) {
			return $http.put('/api/centros-custo/' + centroCustoData.id, centroCustoData);
		},
		deleteCentroCusto: function(id) {
			return $http.delete('/api/centros-custo/' + id);
		}
	};
}])