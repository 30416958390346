app.factory('Brinde', ['$http', function($http){
	return {
		getBrindes: function() {
			return $http.get('/api/brindes');
		},
		getBrinde: function(id) {
			return $http.get('/api/brindes/' + id);	
		},
		insertBrinde: function (brindeData) {
			return $http.post('/api/brindes', brindeData);
		},
		updateBrinde: function (brindeData) {
			return $http.put('/api/brindes/' + brindeData.id, brindeData);
		},
		deleteBrinde: function(id) {
			return $http.delete('/api/brindes/' + id);
		}
	};
}])