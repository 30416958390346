app.controller('TipoListCtrl', ['$scope', 'Tipo', 'toastr', 'SweetAlert', '$state', function($scope, Tipo, toastr, SweetAlert, $state) {
	$scope.tipos = [];
	$scope.tiposPerPage = 10;

	$scope.refreshTipos = function() {
		Tipo.getTipos()
			.then(function(response) {
				$scope.tipos = response.data;
			});
	}

	$scope.refreshTipos();

	$scope.deleteTipo = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste tipo de pessoa não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Tipo.deleteTipo(id)
					.then(function() {
						toastr.success('Tipo de pessoa removido com sucesso.');
						$scope.refreshTipos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este tipo de pessoa porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);