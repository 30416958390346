app.factory('TipoRelacionamento', ['$http', function($http){
	return {
		getTiposRelacionamento: function() {
			return $http.get('/api/tipos-relacionamento');
		},
		getTipoRelacionamento: function(id) {
			return $http.get('/api/tipos-relacionamento/' + id);	
		},
		insertTipoRelacionamento: function (tipoData) {
			return $http.post('/api/tipos-relacionamento', tipoData);
		},
		updateTipoRelacionamento: function (tipoData) {
			return $http.put('/api/tipos-relacionamento/' + tipoData.id, tipoData);
		},
		deleteTipoRelacionamento: function(id) {
			return $http.delete('/api/tipos-relacionamento/' + id);
		}
	};
}])