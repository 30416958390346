app.controller('OrigemEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Origem', 'CentroCusto', function($scope, $state, $stateParams, toastr, Origem, CentroCusto) {
	var vm = this;
	vm.origemId = $stateParams.origemId;

	getOrigem();

	function getOrigem() {
		Origem.getOrigem(vm.origemId)
			.success(function(response) {
				vm.origem = response;
			});
	}

	$scope.updateOrigem = function() {
		Origem.updateOrigem(vm.origem)
			.then(function() {
				toastr.success('Origem alterada com sucesso.');
				$state.go('dashboard.origens.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.origemForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);