app.controller('RoleEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Role', 'Permission', 'ivhTreeviewMgr', function($scope, $state, $stateParams, toastr, Role, Permission, ivhTreeviewMgr) {
	var vm = this;
	vm.roleId = $stateParams.roleId;
	vm.permissions = [];

	getPermissions();

	function getPermissions() {
		Permission.getPermissions()
			.success(function(results) {
				var permissions = {};
				for (var i = 0; i < results.length; i++) {
					var result = results[i];
					if (!permissions[result.category])
						permissions[result.category] = [];
					permissions[result.category].push(result);
				}
				angular.forEach(permissions, function(perms, category) {
					var item = { label: category, value: category.toLowerCase(), children: [] };
					for (var i = 0; i < perms.length; i++) {
						var perm = perms[i];
						item.children.push({
							label: perm.human_name,
							value: perm.name,
							id: perm.id
						});
					}
					vm.permissions.push(item);
				});
				getRole();
			});
	}

	function getRole() {
		Role.getRole(vm.roleId)
			.success(function(response) {
				vm.role = response;
				ivhTreeviewMgr.selectEach(vm.permissions, response.permissions_ids);
			});
	}

	$scope.updateRole = function() {
		Role.updateRole(vm.role)
			.then(function() {
				toastr.success('Perfil alterado com sucesso.');
				$state.go('dashboard.roles.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.roleForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.changeCallback = function(node, isSelected, tree) {
		if (node.children && node.children.length > 0) {
			angular.forEach(node.children, function(childNode) {
				$scope.changeCallback(childNode, isSelected, tree);
			});
			return false;
		}
		if (isSelected) {
			var index = vm.role.permissions_ids.indexOf(node.id);
			if(index == -1){
				vm.role.permissions_ids.push(node.id);
			}
		} else {
			var index = vm.role.permissions_ids.indexOf(node.id);
			vm.role.permissions_ids.splice(index, 1);
		}
	}
}]);