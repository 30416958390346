app.controller('RoleListCtrl', ['$scope', 'Role', 'toastr', 'SweetAlert', '$state', function($scope, Role, toastr, SweetAlert, $state) {
	$scope.roles = [];
	$scope.rolesPerPage = 10;

	$scope.refreshRoles = function() {
		Role.getRoles()
			.then(function(response) {
				$scope.roles = response.data;
			});
	}

	$scope.refreshRoles();

	$scope.deleteRole = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste perfil não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Role.deleteRole(id)
					.then(function() {
						toastr.success('Perfil removido com sucesso.');
						$scope.refreshRoles();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este perfil porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);