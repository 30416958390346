app.directive('dropdownMenu', [function () {
	return {
		restrict: 'A',
		link: function (scope, element, attrs) {
			$(element).hover(function() {
				$(this).find('.dropdown-menu').stop(true, true).fadeIn();
			}, function() {
				$(this).find('.dropdown-menu').stop(true, true).delay(100).fadeOut();
			});
		}
	};
}])