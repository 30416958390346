app.directive('formatDate', ['$parse', function ($parse) {
  return {
    restrict: 'A',
    require: 'ngModel',
    link: function link(scope, element, attrs, ngModel) {
      scope.$watch(function() { 
        return ngModel.$modelValue;
      }, function(newValue, oldValue) {
        if (!newValue || angular.isDate(newValue)) {
          return false;
        }

        var dt = new Date(newValue);
        var modelGetter = $parse(attrs.ngModel);
        var modelSetter = modelGetter.assign;
        modelSetter(scope, dt);
      }, true)
    }
  };
}]);