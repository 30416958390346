app.filter('range', [function () {
	return function(input, start, total) {
		start = parseInt(start);
		total = parseInt(total);

		if (isNaN(total)) {
			total = start;
			start = 0;
		}

		for (var i=start; i<total; i++) {
			input.push(i);
		}

		return input;
	};
}])