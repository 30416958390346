app.controller('CentroCustoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'CentroCusto', function($scope, $state, $stateParams, toastr, CentroCusto) {
	var vm = this;
	vm.centroCustoId = $stateParams.centroCustoId;

	getCentroCusto();

	function getCentroCusto() {
		CentroCusto.getCentroCusto(vm.centroCustoId)
			.success(function(response) {
				vm.centroCusto = response;
			});
	}

	$scope.updateCentroCusto = function() {
		CentroCusto.updateCentroCusto(vm.centroCusto)
			.then(function() {
				toastr.success('Centro de custo alterado com sucesso.');
				$state.go('dashboard.centros_custo.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.centroCustoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);