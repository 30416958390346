app.factory('Contrato', ['$http', function($http){
	return {
		getContratos: function(pageNumber, searchText, sortType, sortReverse) {
			return $http.get('/api/contratos?' + (pageNumber ? 'page=' + pageNumber : '') + (searchText ? '&q=' + searchText : '') + (sortType ? '&sort=' + sortType : '') + '&dir=' + (sortReverse ? 'desc' : 'asc'));
		},
		getModulosByContrato: function(pessoaId, produtoId) {
			return $http.get('/api/contratos/' + pessoaId + '/' + produtoId + '/modulos');
		},
		getContrato: function(id) {
			return $http.get('/api/contratos/' + id);	
		},
		insertContrato: function (contratoData) {
			return $http.post('/api/contratos', contratoData);
		},
		updateContrato: function (contratoData) {
			return $http.put('/api/contratos/' + contratoData.id, contratoData);
		},
		deleteContrato: function(id) {
			return $http.delete('/api/contratos/' + id);
		}
	};
}])