app.factory('Lancamento', ['$http', function($http){
	return {
		getLancamentos: function(pageNumber, searchText, sortType, sortReverse, pessoaId, filtros) {
			filtros.page = pageNumber;
			filtros.q = searchText;
			filtros.sort = sortType;
			filtros.dir = sortReverse ? 'desc' : 'asc';
			filtros.pessoaId = filtros.pessoa ? filtros.pessoa.id : '';
			return $http({
				url: '/api/lancamentos',
				method: 'GET', 
				params: filtros
			});
		},
		getLancamento: function(id) {
			return $http.get('/api/lancamentos/' + id);	
		},
		insertLancamento: function (lancamentoData) {
			return $http.post('/api/lancamentos', lancamentoData);
		},
		insertLote: function (loteData) {
			return $http.post('/api/lancamentos/lote', loteData);
		},
		updateLancamento: function (lancamentoData) {
			return $http.put('/api/lancamentos/' + lancamentoData.id, lancamentoData);
		},
		deleteLancamento: function(id) {
			return $http.delete('/api/lancamentos/' + id);
		},
		duplicateLancamento: function(id, quantity, duplicateData) {
			return $http.post('/api/lancamentos/' + id + '/duplicate/' + quantity, duplicateData);
		}
	};
}])