app.controller('LancamentoEmLoteCtrl', ['$scope', '$state', 'toastr', 'Lancamento', 'Classificacao', 'CentroCusto', 'Banco', 'FormaPagamento', 'Pessoa', 'Contrato', '$stateParams', '$filter', '$timeout', 'Auth', function($scope, $state, toastr, Lancamento, Classificacao, CentroCusto, Banco, FormaPagamento, Pessoa, Contrato, $stateParams, $filter, $timeout, Auth) {
  $scope.pessoas = [];
  $scope.lote = {
    tipo: 'D',
    status: 'P',
    data_emissao: new Date(),
    lancamentos: [],
    conta_id: null
  };

  $scope.popupDataEmissao = {
    opened: false
  };

  $scope.popupDataVencimento = {
    opened: false
  };

  $scope.popupDataPagamento = {
    opened: false
  };

  $scope.openDataEmissao = function() {
    $scope.popupDataEmissao.opened = true;
  }

  $scope.openDataVencimento = function() {
    $scope.popupDataVencimento.opened = true;
  }

  $scope.openDataPagamento = function() {
    $scope.popupDataPagamento.opened = true;
  }

  getClassificacaos();
  getBancos();
  getCentrosCusto();
  getFormasPagamento();
  getConta();

  $scope.refreshPessoas = function(search) {
    Pessoa.getPessoas(0, 10, search, 'id,razao_social')
      .then(function(response) {
        $scope.pessoas = response.data.items;
      });
  }

  function getClassificacaos(){
      Classificacao.getClassificacaos()
      .then(function(response) {
        $scope.classificacaos = response.data;
      });
  }

  function getBancos(){
    Banco.getBancos()
      .then(function(response) {
        $scope.bancos = response.data;
      });
  }

  function getCentrosCusto(){
    CentroCusto.getCentrosCusto()
      .then(function(response) {
        $scope.centros_custo = response.data;
      });
  }

  function getFormasPagamento(){
    FormaPagamento.getFormasPagamento()
      .then(function(response) {
        $scope.formas_pagamento = response.data;
      });
  }


  $scope.addLancamento = function() {
    $scope.lote.lancamentos.push({
      valor_bruto: 0.0,
      desconto: 0.0,
      valor_liquido: 0.0,
    });
  }

  $scope.removeLancamento = function(index) {
    $scope.lote.lancamentos.splice(index, 1);
  }

  $scope.duplicateLancamento = function(index) {
    var newItem = Object.assign({}, $scope.lote.lancamentos[index]);
    delete newItem.$$hashKey;
    if (newItem.classificacao) {
      delete newItem.classificacao.$$hashKey;
    }
    $scope.lote.lancamentos.push(newItem);
  }
  
  function getConta(){
	  var currentUser = Auth.getCurrentUser();
	  $scope.lote.conta_id = currentUser.conta_id;
  }

  $scope.saveLote = function() {
    Lancamento.insertLote($scope.lote)
      .then(function() {
        toastr.success('Lançamentos inseridos com sucesso.');
        $state.go('dashboard.lancamentos.list');
      })
      .catch(function(response) {
        $scope.errorMessage = {};
        angular.forEach(response.data, function(errors, index) {
          angular.forEach(errors, function(messages, field) {
            var formName = $scope.formName(index);
            $scope[formName][field].$setValidity('server', false);
            if ($scope.errorMessage[$index] == undefined) {
              $scope.errorMessage[$index] = {};
            }
            $scope.errorMessage[$index][field] = messages[0];
          });
        });
      });
  }

  $scope.formName = function(index) {
    return 'lancamentoForm_' + index;
  }

  $scope.getForm = function(index) {
    var formName = $scope.formName(index);
    return $scope.loteForm[formName];
  }

  $scope.updateValorLiquido = function(idx) {
    $scope.lote.lancamentos[idx].valor_liquido =
      $scope.lote.lancamentos[idx].valor_bruto -
      $scope.lote.lancamentos[idx].desconto;
  }

  $scope.preencherCentroCusto = function(index) {
    var lancamento = $scope.lote.lancamentos[index];
    if (lancamento.classificacao && lancamento.classificacao.centro_custo_id) {
      lancamento.centro_custo_id = lancamento.classificacao.centro_custo_id;
    }
  }

  $scope.soma = function() {
    var soma = 0;
    angular.forEach($scope.lote.lancamentos, function(lancamento) {
      soma += lancamento.valor_liquido;
    });
    return soma;
  }

  $scope.resumo = function() {
    var resumo = {};
    angular.forEach($scope.lote.lancamentos, function(lancamento) {
      var classificacao = lancamento.classificacao ? lancamento.classificacao.nome : '---';
      if (!resumo[classificacao]) {
        resumo[classificacao] = 0;
      }
      resumo[classificacao] += lancamento.valor_liquido;
    });
    return resumo;
  }
}]);