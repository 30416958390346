app.controller('PessoaNewCtrl', ['$scope', '$location', 'toastr', 'Pessoa', 'Setor', 'Origem', 'RamoAtuacao', '$uibModal', 'Cep', 'TipoRelacionamento', 'TipoContato', 'Motivo', 'Produto', 'Auth', function($scope, $location, toastr, Pessoa, Setor, Origem, RamoAtuacao, $uibModal, Cep, TipoRelacionamento, TipoContato, Motivo, Produto, Auth) {
	var vm = this;
	vm.editingContato = {};
	vm.pessoa = {
		natureza: 'PJ',
		ativo: true,
		contatos: [],
		tipos_ids: [],
		historico_contatos: [],
        
	};

	getSetores();
	getRamosAtuacao();
	getOrigens();
	getTiposRelacionamento();
	getTiposContato();
	getProdutos();
	getMotivos();

	function getSetores(){
		Setor.getSetores()
			.then(function(response) {
				vm.setores = response.data;
			});
	}

	function getRamosAtuacao(){
		RamoAtuacao.getRamosAtuacao()
			.then(function(response) {
				vm.ramos_atuacao = response.data;
			});
	}

	function getOrigens(){
		Origem.getOrigens()
			.then(function(response) {
				vm.origens = response.data;
			});
	}

	function getTiposRelacionamento() {
		TipoRelacionamento.getTiposRelacionamento()
			.then(function(response) {
				$scope.tipos = response.data;
			})
	}

	function getTiposContato(){
		TipoContato.getTiposContato()
			.then(function(response) {
				$scope.tipos_contato = response.data;
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				$scope.produtos = response.data;
			});
	}

	function getMotivos(){
		Motivo.getMotivos()
			.then(function(response) {
				$scope.motivos = response.data;
			});
	}

	$scope.insertPessoa = function() {
		Pessoa.insertPessoa(vm.pessoa)
			.then(function() {
				toastr.success('Pessoa salva com sucesso.');
				$location.path('/pessoas');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.pessoaForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.editContato = function(index) {
		vm.editingContato = angular.copy(vm.pessoa.contatos[index], {});
		vm.editingContatoIndex = index;
	}

	$scope.saveContato = function() {
		if (vm.editingContatoIndex >= 0) {
			vm.pessoa.contatos[vm.editingContatoIndex] = vm.editingContato;
			vm.editingContatoIndex = null;
		} else {
			var dt = new Date();
			var timestamp = dt.getTime();
			vm.editingContato.temp_id = '__' + timestamp + '__';
			vm.pessoa.contatos.push(vm.editingContato);
		}
		vm.editingContato = {};
		vm.contatoForm.$setPristine();
	}

	$scope.deleteContato = function(index) {
		vm.pessoa.contatos.splice(index, 1);
	}

	$scope.boolToStr = function(value) {
		return value ? 'Sim' : 'Não';
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				var items = response.data.items;
				items.push({id: null, razao_social: '-- Nenhum --'});
				vm.pessoas = items;
			});
	}

	$scope.addHistoricoContato = function() {
		var currentUser = Auth.getCurrentUser();

		var modalInstance = $uibModal.open({
			templateUrl: '/views/pessoas/_historico_contato.html',
			controller: 'HistoricoContatoEditCtrl',
			scope: $scope,
			resolve: {
				historico_contato: {
					data: new Date(),
					user: currentUser,
					status: 'A',
					produtos_ids: []
				},
				vm: vm
			}
		});

		modalInstance.result.then(function(historico_contato) {
			var dt = new Date();
			var timestamp = dt.getTime();
			historico_contato.temp_id = '__' + timestamp + '__';

			vm.pessoa.historico_contatos.push(historico_contato);
		});
	}

	$scope.editHistoricoContato = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/pessoas/_historico_contato.html',
			controller: 'HistoricoContatoEditCtrl',
			scope: $scope,
			resolve: {
				historico_contato: vm.pessoa.historico_contatos[index],
				vm: vm
			}
		})

		modalInstance.result.then(function(historico_contato) {
			vm.pessoa.historico_contatos[index] = historico_contato;
		});
	}

	$scope.deleteHistoricoContato = function(index) {
		if (vm.pessoa.historico_contatos[index].id) {
			vm.pessoa.historico_contatos[index]._destroy = true;
		} else {
			vm.pessoa.historico_contatos.splice(index, 1);
		}
	}

	$scope.buscarCep = function() {
		var cep = vm.pessoa.endereco.cep;

		if (!cep) {
			return false;
		};

		Cep.search(cep)
			.then(function(response) {
				var dados = response.data;
				if (dados.tipo == 1) {
					vm.pessoa.endereco.uf = dados.uf;
					vm.pessoa.endereco.cidade = dados.cidade;
					vm.pessoa.endereco.logradouro = dados.logradouro;
					vm.pessoa.endereco.bairro = dados.bairro;
				} else if (dados.tipo == 2) {
					vm.pessoa.endereco.uf = dados.uf;
					vm.pessoa.endereco.cidade = dados.cidade;
				} else {
					toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
				}
			}, function() {
				toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
			});
	}

	$scope.checarCNPJ = function() {
		var cnpj = vm.pessoa.cnpj;
		//Primeiro verifica se encontra registro vinculado a conta logada
		Pessoa.getPessoaByCNPJ(cnpj)
			.then(function(response) {
				var modalInstance = $uibModal.open({
					templateUrl: '/views/pessoas/_modal.html',
					controller: 'PessoaExistenteModalCtrl',
					scope: $scope,
					resolve: {
						pessoa: response.data
					}
				});
			},
			//Se não tem registro na conta logada verifica se tem registro só com o cnpj
			function(response){
				Pessoa.getPessoaByCNPJ(cnpj, true)
				.then(function(response) {
					var modalInstance = $uibModal.open({
						templateUrl: '/views/pessoas/_modal_vinculo.html',
						controller: 'PessoaExistenteModalCtrl',
						scope: $scope,
						resolve: {
							pessoa: response.data
						}
					});
				});
			})
	}
}]);
