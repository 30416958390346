app.factory('RamoAtuacao', ['$http', function($http){
	return {
		getRamosAtuacao: function() {
			return $http.get('/api/ramos-atuacao');
		},
		getRamoAtuacao: function(id) {
			return $http.get('/api/ramos-atuacao/' + id);	
		},
		insertRamoAtuacao: function (ramoAtuacaoData) {
			return $http.post('/api/ramos-atuacao', ramoAtuacaoData);
		},
		updateRamoAtuacao: function (ramoAtuacaoData) {
			return $http.put('/api/ramos-atuacao/' + ramoAtuacaoData.id, ramoAtuacaoData);
		},
		deleteRamoAtuacao: function(id) {
			return $http.delete('/api/ramos-atuacao/' + id);
		}
	};
}])