app.controller('RamoAtuacaoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'RamoAtuacao', function($scope, $state, $stateParams, toastr, RamoAtuacao) {
	var vm = this;
	vm.ramoAtuacaoId = $stateParams.ramoAtuacaoId;

	getRamoAtuacao();

	function getRamoAtuacao() {
		RamoAtuacao.getRamoAtuacao(vm.ramoAtuacaoId)
			.success(function(response) {
				vm.ramoAtuacao = response;
			});
	}

	$scope.updateRamoAtuacao = function() {
		RamoAtuacao.updateRamoAtuacao(vm.ramoAtuacao)
			.then(function() {
				toastr.success('Ramo de atuação alterado com sucesso.');
				$state.go('dashboard.ramos_atuacao.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.ramoAtuacaoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);