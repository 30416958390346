app.factory('OrigemTransacao', ['$http', function($http){
	return {
		getOrigens: function() {
			return $http.get('/api/origem-transacao');
		},
		getOrigem: function(id) {
			return $http.get('/api/origem-transacao/' + id);	
		},
		insertOrigem: function (origemData) {
			return $http.post('/api/origem-transacao', origemData);
		},
		updateOrigem: function (origemData) {
			return $http.put('/api/origem-transacao/' + origemData.id, origemData);
		},
		deleteOrigem: function(id) {
			return $http.delete('/api/origem-transacao/' + id);
		}
	};
}])