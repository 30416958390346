app.controller('CentroCustoListCtrl', ['$scope', 'CentroCusto', 'toastr', 'SweetAlert', '$state', function($scope, CentroCusto, toastr, SweetAlert, $state) {
	$scope.centrosCusto = [];
	$scope.centrosCustoPerPage = 10;

	$scope.refreshCentrosCusto = function() {
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				$scope.centrosCusto = response.data;
			});
	}

	$scope.refreshCentrosCusto();

	$scope.deleteCentroCusto = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste centro de custo não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				CentroCusto.deleteCentroCusto(id)
					.then(function() {
						toastr.success('Centro de custo removido com sucesso.');
						$scope.refreshCentrosCusto();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este centro de custo porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);