app.factory('Profissao', ['$http', function($http){
	return {
		getProfissoes: function() {
			return $http.get('/api/profissoes');
		},
		getProfissao: function(id) {
			return $http.get('/api/profissoes/' + id);	
		},
		insertProfissao: function (profissaoData) {
			return $http.post('/api/profissoes', profissaoData);
		},
		updateProfissao: function (profissaoData) {
			return $http.put('/api/profissoes/' + profissaoData.id, profissaoData);
		},
		deleteProfissao: function(id) {
			return $http.delete('/api/profissoes/' + id);
		}
	};
}])