app.controller('LoginCtrl', ['$scope', '$http', 'Auth', '$state', 'toastr', 'Conta', function ($scope, $http, Auth, $state, toastr, Conta) {
	$scope.users = [];
	$scope.contaEncontrado = false;
	$scope.contaId = 0;

	$scope.login = function() {
		$http.post('/api/auth', {user_id: $scope.user_id, password: $scope.password, conta_id: $scope.contaId})
			.then(function(response) {
				Auth.saveToken(response.data.token);
				Auth.setCurrentUser(response.data.user);
				Auth.setPermissions(response.data.permissions);
				toastr.success('Sessão iniciada com sucesso.');
				$state.go('dashboard.home');
			})
			.catch(function(response) {
				toastr.error(response.data.message);
			});
	}

	$scope.loadUsers = function() {
		$scope.contaEncontrado = false;
		$scope.users = [];

        Conta.getContaPorCNPJ($scope.cnpj)
			.then(function(response) {
				$scope.contaEncontrado = true;
				$scope.users = response.data.users;
				$scope.contaId = response.data.id;
			})
			.catch(function(response) {
				toastr.error('Conta não encontrado.');
			});
	}
}]);
