app.controller('SetorNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Setor', function($scope, $state, $stateParams, toastr, Setor) {
	var vm = this;

	$scope.insertSetor = function() {
		Setor.insertSetor(vm.setor)
			.then(function() {
				toastr.success('Setor salvo com sucesso.');
				$state.go('dashboard.setores.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.setorForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);