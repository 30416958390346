app.factory('Conta', ['$http', function($http){
	return {
		getContas: function(offset, limit, search, columns) {
			return $http.get('/api/contas?' + (offset > 0 ? '&offset=' + offset : '') + (limit > 0 ? '&limit=' + limit : '') + '&q=' + search + (columns ? '&columns=' + columns : ''));
		},
        getConta: function(id) {
			return $http.get('/api/contas/' + id);
		},
        getContaPorCNPJ: function(cnpj) {
			return $http.get('/api/contas/por-cnpj/' + cnpj);
		},
        insertConta: function (contaData) {
			return $http.post('/api/contas', contaData);
		},
        updateConta: function (contaData) {
			return $http.put('/api/contas/' + contaData.id, contaData);
		},
        deleteConta: function(id) {
			return $http.delete('/api/contas/' + id);
		}
	};
}])
