app.factory('FormaPagamento', ['$http', function($http){
	return {
		getFormasPagamento: function() {
			return $http.get('/api/formas-pagamento');
		},
		getFormaPagamento: function(id) {
			return $http.get('/api/formas-pagamento/' + id);	
		},
		insertFormaPagamento: function (formaPagamentoData) {
			return $http.post('/api/formas-pagamento', formaPagamentoData);
		},
		updateFormaPagamento: function (formaPagamentoData) {
			return $http.put('/api/formas-pagamento/' + formaPagamentoData.id, formaPagamentoData);
		},
		deleteFormaPagamento: function(id) {
			return $http.delete('/api/formas-pagamento/' + id);
		}
	};
}])