app.controller('AtendimentoEditCtrl', ['$scope', '$state', 'toastr', 'Atendimento', 'Status', 'TipoContato', 'Produto', 'ModuloProduto', 'Pessoa', 'Motivo', 'Erro', '$uibModal', '$stateParams', '$filter', '$timeout', 'User', 'Prioridade', 'STATUS_FINALIZADO', 'STATUS_PENDENTE', 'Auth', '$rootScope', 'STATUS_CANCELADA', 'STATUS_EM_DESENVOLVIMENTO', 'STATUS_EM_ANALISE', 'STATUS_ATIVO', 'Contrato', 'SweetAlert', function($scope, $state, toastr, Atendimento, Status, TipoContato, Produto, ModuloProduto, Pessoa, Motivo, Erro, $uibModal, $stateParams, $filter, $timeout, User, Prioridade, STATUS_FINALIZADO, STATUS_PENDENTE, Auth, $rootScope, STATUS_CANCELADA, STATUS_EM_DESENVOLVIMENTO, STATUS_EM_ANALISE, STATUS_ATIVO, Contrato, SweetAlert) {
	var vm = this;
	vm.atendimentoId = $stateParams.atendimentoId;
	vm.errorMessage = {};
	vm.initiated = false;
	vm.intervencaoIdx = -1;

	getStatuses();
	getTiposContato();
	getProdutos();
	getMotivos();
	getErros();
	getAtendimento();
	getUsers();
	getPrioridades();

	function getStatuses(){
		Status.getStatuses()
			.then(function(response) {
				vm.statuses = response.data;
			});
	}

	function getTiposContato(){
		TipoContato.getTiposContato()
			.then(function(response) {
				vm.tipos_contato = response.data;
			});
	}

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				vm.produtos = response.data;
			});
	}

	function getModulosProdutos(pessoaId, produtoId){
		if (!produtoId || produtoId.length == 0 ||
			!pessoaId || pessoaId.length == 0) {
			vm.modulos_produtos = [];
			return false;
		}

		Contrato.getModulosByContrato(pessoaId, produtoId)
			.then(function(response) {
				vm.modulos_produtos = response.data;
			});
	}

	function getMotivos(){
		Motivo.getMotivos()
			.then(function(response) {
				vm.motivos = response.data;
			});
	}

	function getErros(){
		Erro.getErros()
			.then(function(response) {
				vm.erros = response.data;
			});
	}

	function getAtendimento() {
		Atendimento.getAtendimento(vm.atendimentoId)
			.success(function(response) {
				vm.atendimento = response;
				vm.atendimento.arquivo = {};
				$timeout(function() { vm.initiated = true; })
			});
	}

	function getUsers() {
		User.getUsers()
			.then(function(response) {
				vm.users = response.data;
			});
	}

	function getPrioridades() {
		Prioridade.getPrioridades()
			.then(function(response) {
				vm.prioridades = response.data;
			});
	}

	$scope.updateAtendimento = function(redirect) {
		if (vm.intervencaoIdx >= 0) {
			var statuses = $filter('filter')(vm.statuses, {id: STATUS_FINALIZADO});
			vm.atendimento.intervencoes[vm.intervencaoIdx].status = statuses[0];
    		vm.atendimento.intervencoes[vm.intervencaoIdx].data_fechamento = new Date();
		}
		Atendimento.updateAtendimento(vm.atendimento)
			.then(function() {
				vm.atendimentoForm.$setPristine();
				toastr.success('Atendimento alterado com sucesso.');
				if (redirect) {
					$state.go('dashboard.atendimentos.list', {'filtros': $stateParams.filtros});
				} else {
					getAtendimento();
				}
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.atendimentoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', null, true, true)
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	vm.popupDataAbertura = {
		opened: false
	};

	vm.popupDataFechamento = {
		opened: false
	};

	vm.popupDataNovoContato = {
		opened: false
	};

	$scope.openDataAbertura = function() {
		vm.popupDataAbertura.opened = true;
	}

	$scope.openDataFechamento = function() {
		vm.popupDataFechamento.opened = true;
	}

	$scope.openDataNovoContato = function() {
		vm.popupDataNovoContato.opened = true;
	}

	$scope.editIntervencao = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/atendimentos/_intervencao.html',
			controller: 'IntervencaoEditCtrl',
			scope: $scope,
			resolve: {
				intervencao: vm.atendimento.intervencoes[index]
			}
		})

		modalInstance.result.then(function(intervencao) {
			vm.atendimento.intervencoes[index] = intervencao;
		});
	}

	$scope.deleteIntervencao = function(index) {
		if (vm.atendimento.intervencoes[index].id) {
			vm.atendimento.intervencoes[index]._destroy = true;
		} else {
			vm.atendimento.intervencoes.splice(index, 1);
		}
	}

	$scope.addSolicitacao = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/atendimentos/_solicitacao.html',
			controller: 'SolicitacaoEditCtrl',
			scope: $scope,
			resolve: {
				solicitacao: {
					status_id: STATUS_PENDENTE
				}
			}
		});

		modalInstance.result.then(function(solicitacao) {
			if (solicitacao.implantado_em) {
				var statuses = $filter('filter')(vm.statuses, {id: STATUS_FINALIZADO});
				solicitacao.status = statuses[0];
			}

			var dt = new Date();
			var timestamp = dt.getTime();
			solicitacao.temp_id = '__' + timestamp + '__';

			vm.atendimento.solicitacoes.push(solicitacao);
		});
	}

	$scope.editSolicitacao = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/atendimentos/_solicitacao.html',
			controller: 'SolicitacaoEditCtrl',
			scope: $scope,
			resolve: {
				solicitacao: vm.atendimento.solicitacoes[index]
			}
		})

		modalInstance.result.then(function(solicitacao) {
			if (solicitacao.implantado_em) {
				var statuses = $filter('filter')(vm.statuses, {id: STATUS_FINALIZADO});
				solicitacao.status = statuses[0];
			}

			vm.atendimento.solicitacoes[index] = solicitacao;
		});
	}

	$scope.deleteSolicitacao = function(index) {
		if (vm.atendimento.solicitacoes[index].id) {
			vm.atendimento.solicitacoes[index]._destroy = true;
		} else {
			vm.atendimento.solicitacoes.splice(index, 1);
		}
	}

	$scope.duplicateSolicitacao = function(index) {
		var solicitacao = angular.copy(vm.atendimento.solicitacoes[index], {});
		var random = parseInt(Math.random() * Math.pow(10,10));
		solicitacao.temp_id = '__' + random + '__';
		delete solicitacao['id'];
		vm.atendimento.solicitacoes.push(solicitacao);
	}

	$scope.addAnexo = function() {
		vm.atendimento.anexos.push({
			file: '',
			enviando: false
		});
	}

	$scope.deleteAnexo = function(index) {
		if (vm.atendimento.anexos[index].id) {
			vm.atendimento.anexos[index]._destroy = true;
		} else {
			vm.atendimento.anexos.splice(index, 1);
		}
	}

	$scope.uploadAnexo = function(index) {
		var arquivo = vm.atendimento.anexos[index].file;
		vm.atendimento.anexos[index].enviando = true;
		Atendimento.enviarArquivo(vm.atendimento.id, arquivo)
			.then(function(response) {
				vm.atendimento.anexos[index] = response.data;
			})
			.catch(function(response) {
				toastr.error('Erro ao enviar arquivo.');
			});
	}

	$scope.createModuloProduto = function(descricao, callback) {
		ModuloProduto.insertModuloProduto({
			descricao: descricao,
			produto_id: vm.atendimento.produto_id
		}).then(function(response) {
				vm.modulos_produtos.push(response.data);
				callback(response.data);
			});
	}

	$scope.avaliarSolicitacao = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/atendimentos/_avaliar-solicitacao.html',
			scope: $scope,
			resolve: {
				solicitacao: vm.atendimento.solicitacoes[index]
			},
			controller: 'AvaliarSolicitacaoCtrl'
		});
	}

	$scope.iniciarIntervencao = function(solicitacao) {
		var statuses = $filter('filter')(vm.statuses, {id: STATUS_PENDENTE});
		vm.atendimentoForm.$setDirty();
		var dt = new Date();
		var timestamp = dt.getTime();
		var solicitacaoId = null;
		if (solicitacao) {
			solicitacaoId = solicitacao.id ? solicitacao.id : solicitacao.temp_id;
		}
		vm.atendimento.intervencoes.push({
			temp_id: temp_id = '__' + timestamp + '__',
			solicitacao_id: solicitacaoId,
			data_abertura: new Date(),
			user: Auth.getCurrentUser(),
			status: statuses[0]
		});
		return vm.atendimento.intervencoes.length - 1;
	}

	$scope.finalizarIntervencao = function(idx) {
		var statuses = $filter('filter')(vm.statuses, {id: STATUS_PENDENTE});
		vm.atendimento.intervencoes[idx].status = statuses[0];
		$scope.editIntervencao(idx);
	}

	$scope.abrirSolicitacao = function(solicitacaoId) {
		if (typeof solicitacaoId == 'string') {
			for (var i = 0; i < vm.atendimento.solicitacoes.length; i++) {
				if (vm.atendimento.solicitacoes[i].temp_id = solicitacaoId) {
					$scope.editSolicitacao(i);
					return false;
				}
			}
		} else {
			for (var i = 0; i < vm.atendimento.solicitacoes.length; i++) {
				if (vm.atendimento.solicitacoes[i].id = solicitacaoId) {
					$scope.editSolicitacao(i);
					return false;
				}
			}
		}
	}

	$scope.$watch('vm.atendimento.contato_id', function(newValue, oldValue) {
		if (vm.initiated && vm.atendimento.pessoa && !vm.atendimento.telefone) {
			var contatos = $filter('filter')(vm.atendimento.pessoa.contatos, {
				id: newValue
			});

			if (contatos.length > 0) {
				vm.atendimento.telefone = contatos[0]['telefone'];
			}
		}
	});

	$scope.$watch('vm.atendimento.produto_id', function(newValue, oldValue) {
		if (vm.atendimento && vm.atendimento.pessoa) {
			getModulosProdutos(vm.atendimento.pessoa.id, newValue);
		}
	});

	$scope.statusSolicitacao = function(solicitacao) {
		var statusId = $scope.getStatusSolicitacao(solicitacao);
		solicitacao.status_id = statusId;
		var results = $filter('filter')(vm.statuses, {id: statusId});		
		if (results && results.length > 0) {
			return results[0].descricao;
		} else {
			return '';
		}
	}

	$scope.getStatusSolicitacao = function(solicitacao) {
		var statusId;
		if (solicitacao.implantado_por_id != null) {
			statusId = STATUS_FINALIZADO;
		} else {
			if (solicitacao.encaminhar_para && solicitacao.encaminhar_para.id == 2) {
				statusId = STATUS_EM_ANALISE;
			} else {		
				var intervencoes = $filter('filter')(vm.atendimento.intervencoes, {
					solicitacao_id: (solicitacao.id ? solicitacao.id : solicitacao.temp_id)
				});
				if (intervencoes.length > 0) {
					statusId = STATUS_EM_DESENVOLVIMENTO;
				} else {
					if (solicitacao.cancelada_por_id != null) {
						statusId = STATUS_CANCELADA;
					} else {
						statusId = STATUS_PENDENTE;
					}
				}
			}
		}
		return statusId;
	}

	$scope.$watch('vm.atendimento.solicitacoes', function(newVal, oldVal) {
		$scope.updateStatusAtendimento();
	}, true);

	$scope.updateStatusAtendimento = function() {
		if (!vm.initiated) return false;
		var statusId = STATUS_ATIVO;
		var concluidas = [];
		for (var i = 0; i < vm.atendimento.solicitacoes.length; i++) {
			if ($scope.getStatusSolicitacao(vm.atendimento.solicitacoes[i]) == STATUS_FINALIZADO) {
				concluidas.push(vm.atendimento.solicitacoes);
			}
		}
		if (concluidas.length == vm.atendimento.solicitacoes.length) {
			statusId = STATUS_FINALIZADO;
		}
		vm.atendimento.status_id = statusId;
	}

	$scope.produtosDaPessoa = function() {
		var produtosIds = [];

		if (!vm.atendimento || !vm.atendimento.pessoa || !vm.produtos) {
			return [];
		}

		angular.forEach(vm.atendimento.pessoa.contratos, function(contrato) {
			produtosIds.push(contrato.produto_id);
		});	

		return vm.produtos.filter(function(produto) {
			return produtosIds.indexOf(produto.id) !== -1;
		});
	}

	$scope.abrirAlerta = function() {
		if (vm.atendimento.pessoa && vm.atendimento.pessoa.id && vm.atendimento.produto_id && vm.intervencaoIdx == -1) {
	    	SweetAlert.swal({
	    		title: 'Registrar Tempo',
				text: 'Deseja registrar o tempo desse Atendimento?',
				type: 'warning',
				cancelButtonText: 'Não',
				confirmButtonColor: "#46B999",
				confirmButtonText: "Sim",
				showCancelButton: true,
				closeOnConfirm: false,
				closeOnCancel: false
	    	}, function(isConfirm) {
	    		if (isConfirm) {
	    			vm.intervencaoIdx = $scope.iniciarIntervencao();
	    			SweetAlert.close();
	    		} else {
			    	SweetAlert.swal({
			    		title: 'Registrar Tempo',
						text: 'Esse atendimento não esta registrando o tempo em que você esta trabalhando, deseja iniciar registro de tempo agora? ',
						type: 'warning',
						cancelButtonText: 'Não',
						confirmButtonColor: "#46B999",
						confirmButtonText: "Sim",
						showCancelButton: true,
			    	}, function(isConfirm) {
			    		if (isConfirm) {
			    			vm.intervencaoIdx = $scope.iniciarIntervencao();
			    		}
			    	});
	    		}
	    	});
		}
	}
}]);