app.controller('UserNewCtrl', ['$scope', '$location', 'toastr', 'User', 'Role', 'Conta', function($scope, $location, toastr, User, Role, Conta) {
	var vm = this;
	vm.user = {
		ativo: 'S'
	};

	getRoles();
	getContas();

	function getRoles() {
		Role.getRoles()
			.then(function(response) {
				vm.roles = response.data;
			});
	}

	function getContas() {
        Conta.getContas(0, 0, '')
			.then(function(response) {
				vm.contas = response.data.items;
			});
	}

	$scope.insertUser = function() {
		User.insertUser(vm.user)
			.then(function() {
				toastr.success('Usuário salvo com sucesso.');
				$location.path('/users');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.userForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);
