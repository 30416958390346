app.controller('FuncaoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Funcao', function($scope, $state, $stateParams, toastr, Funcao) {
	var vm = this;
	vm.funcaoId = $stateParams.funcaoId;

	getFuncao();

	function getFuncao() {
		Funcao.getFuncao(vm.funcaoId)
			.success(function(response) {
				vm.funcao = response;
			});
	}

	$scope.updateFuncao = function() {
		Funcao.updateFuncao(vm.funcao)
			.then(function() {
				toastr.success('Função alterada com sucesso.');
				$state.go('dashboard.funcoes.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.funcaoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);