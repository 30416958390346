app.factory('TipoContato', ['$http', function($http){
	return {
		getTiposContato: function() {
			return $http.get('/api/tipos-contato');
		},
		getTipoContato: function(id) {
			return $http.get('/api/tipos-contato/' + id);	
		},
		insertTipoContato: function (tipoContatoData) {
			return $http.post('/api/tipos-contato', tipoContatoData);
		},
		updateTipoContato: function (tipoContatoData) {
			return $http.put('/api/tipos-contato/' + tipoContatoData.id, tipoContatoData);
		},
		deleteTipoContato: function(id) {
			return $http.delete('/api/tipos-contato/' + id);
		}
	};
}])