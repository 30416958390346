app.controller('TipoContatoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'TipoContato', function($scope, $state, $stateParams, toastr, TipoContato) {
	var vm = this;
	vm.tipoContatoId = $stateParams.tipoContatoId;

	getTipoContato();

	function getTipoContato() {
		TipoContato.getTipoContato(vm.tipoContatoId)
			.success(function(response) {
				vm.tipoContato = response;
			});
	}

	$scope.updateTipoContato = function() {
		TipoContato.updateTipoContato(vm.tipoContato)
			.then(function() {
				toastr.success('Tipo de contato alterado com sucesso.');
				$state.go('dashboard.tipos_contato.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.tipoContatoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);