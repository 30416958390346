app.controller('TipoContatoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'TipoContato', function($scope, $state, $stateParams, toastr, TipoContato) {
	var vm = this;

	$scope.insertTipoContato = function() {
		TipoContato.insertTipoContato(vm.tipoContato)
			.then(function() {
				toastr.success('Tipo de contato salvo com sucesso.');
				$state.go('dashboard.tipos_contato.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.tipoContatoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);