app.factory('Pessoa', ['$http', function($http){
	return {
		getPessoas: function(offset, limit, search, columns, tipoId, trazerContatos, trazerContratos, idAll) {
			return $http.get(
					'/api/pessoas?offset=' + offset + '&limit=' + limit + '&q=' + search + (columns ? '&columns=' + columns : '') 
					+ (tipoId ? '&tipo_id=' + tipoId : '') + (trazerContatos ? '&contatos=1' : '') + (trazerContratos ? '&contratos=1' : '')
					+ (idAll ? '&idall=T' : '')
					);
		},
		getPessoa: function(id) {
			return $http.get('/api/pessoas/' + id);	
		},
		getPessoaByCNPJ: function(cnpj, idSearchAll) {
			return $http.get('/api/pessoas/by-cnpj/' + cnpj + (idSearchAll ? '/Y' : ''));
		},
		insertPessoa: function (pessoaData) {
			return $http.post('/api/pessoas', pessoaData);
		},
		updatePessoa: function (pessoaData) {
			return $http.put('/api/pessoas/' + pessoaData.id, pessoaData);
		},
		deletePessoa: function(id, conta_id) {
			return $http.delete('/api/pessoas/' + id, { params: { conta_id: conta_id } });
		},
		vincularPessoaConta: function(conta_id, pessoaContaData){
			return $http.post('/api/pessoas/vincular-pessoa-conta/' + conta_id, pessoaContaData);
		}
	};
}])