app.factory('DespesaFixa', ['$http', function($http){
	return {
		getDespesasFixas: function() {
			return $http.get('/api/despesas-fixas');
		},
		getDespesaFixa: function(id) {
			return $http.get('/api/despesas-fixas/' + id);	
		},
		insertDespesaFixa: function (despesaFixaData) {
			return $http.post('/api/despesas-fixas', despesaFixaData);
		},
		updateDespesaFixa: function (despesaFixaData) {
			return $http.put('/api/despesas-fixas/' + despesaFixaData.id, despesaFixaData);
		},
		deleteDespesaFixa: function(id) {
			return $http.delete('/api/despesas-fixas/' + id);
		},
		gerarRegistro: function(mes, bancoId) {
			return $http.post('/api/despesas-fixas/gerar/' + mes + '/' + bancoId);
		}
	};
}])