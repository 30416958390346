app.factory('Origem', ['$http', function($http){
	return {
		getOrigens: function() {
			return $http.get('/api/origens');
		},
		getOrigem: function(id) {
			return $http.get('/api/origens/' + id);	
		},
		insertOrigem: function (origemData) {
			return $http.post('/api/origens', origemData);
		},
		updateOrigem: function (origemData) {
			return $http.put('/api/origens/' + origemData.id, origemData);
		},
		deleteOrigem: function(id) {
			return $http.delete('/api/origens/' + id);
		}
	};
}])