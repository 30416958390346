app.controller('AtendimentoModalCtrl', ['$scope', 'Atendimento', 'atendimentoId', '$uibModalInstance', '$timeout', 'STATUS_FINALIZADO', function($scope, Atendimento, atendimentoId, $uibModalInstance, $timeout, STATUS_FINALIZADO) {
	$scope.initiated = false;
	$scope.STATUS_FINALIZADO = STATUS_FINALIZADO;

	getAtendimento();

	function getAtendimento() {
		Atendimento.getAtendimento(atendimentoId)
			.success(function(response) {
				$scope.atendimento = response;
				$timeout(function() { $scope.initiated = true; })
			});
	}

    $scope.close = function() {
        $uibModalInstance.dismiss('close');
    }
}]);
