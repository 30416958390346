app.controller('MotivoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Motivo', function($scope, $state, $stateParams, toastr, Motivo) {
	var vm = this;
	vm.motivoId = $stateParams.motivoId;

	getMotivo();

	function getMotivo() {
		Motivo.getMotivo(vm.motivoId)
			.success(function(response) {
				vm.motivo = response;
			});
	}

	$scope.updateMotivo = function() {
		Motivo.updateMotivo(vm.motivo)
			.then(function() {
				toastr.success('Motivo alterado com sucesso.');
				$state.go('dashboard.motivos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.motivoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);