app.controller('ErroListCtrl', ['$scope', 'Erro', 'toastr', 'SweetAlert', '$state', function($scope, Erro, toastr, SweetAlert, $state) {
	$scope.erros = [];
	$scope.errosPerPage = 10;

	$scope.refreshErros = function() {
		Erro.getErros()
			.then(function(response) {
				$scope.erros = response.data;
			});
	}

	$scope.refreshErros();

	$scope.deleteErro = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste erro não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Erro.deleteErro(id)
					.then(function() {
						toastr.success('Erro removido com sucesso.');
						$scope.refreshErros();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este erro porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);