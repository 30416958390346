app.factory('Plano', ['$http', function($http){
	return {
		getPlanos: function() {
			return $http.get('/api/planos');
		},
		getPlano: function(id) {
			return $http.get('/api/planos/' + id);	
		},
		insertPlano: function (planoData) {
			return $http.post('/api/planos', planoData);
		},
		updatePlano: function (planoData) {
			return $http.put('/api/planos/' + planoData.id, planoData);
		},
		deletePlano: function(id) {
			return $http.delete('/api/planos/' + id);
		}
	};
}])