app.controller('ModuloProdutoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'ModuloProduto', 'Funcao', function($scope, $state, $stateParams, toastr, ModuloProduto, Funcao) {
	var vm = this;
	vm.funcoes = [];

	getFuncoes();

	function getFuncoes() {
		Funcao.getFuncoes()
			.then(function(response) {
				vm.funcoes = response.data;
			});
	}

	$scope.insertModuloProduto = function() {
		ModuloProduto.insertModuloProduto(vm.moduloProduto)
			.then(function() {
				toastr.success('Módulo de produto salvo com sucesso.');
				$state.go('dashboard.modulos_produtos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.moduloProdutoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);