app.controller('PlanoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Plano', function($scope, $state, $stateParams, toastr, Plano) {
	var vm = this;
	vm.planoId = $stateParams.planoId;

	getPlano();

	function getPlano() {
		Plano.getPlano(vm.planoId)
			.success(function(response) {
				vm.plano = response;
			});
	}

	$scope.updatePlano = function() {
		Plano.updatePlano(vm.plano)
			.then(function() {
				toastr.success('Plano alterado com sucesso.');
				$state.go('dashboard.planos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.erroForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);