app.controller('ContratoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Contrato', 'Produto', 'Pessoa', '$uibModal', 'ModuloProduto', 'Funcao', function($scope, $state, $stateParams, toastr, Contrato, Produto, Pessoa, $uibModal, ModuloProduto, Funcao) {
	var vm = this;

	vm.contrato = {
		tipo_cobranca: 'M',
		modulos_produtos: [],
		status: 'A',
		bloqueado: 'N',
		renova_automaticamente: 'N'
	};

	getProdutos();
	getModulosProdutos();
	getFuncoes();

	function getProdutos(){
		Produto.getProdutos()
			.then(function(response) {
				vm.produtos = response.data;
			});
	}

	function getModulosProdutos(){
		ModuloProduto.getModulosProdutos()
			.then(function(response) {
				vm.modulos_produtos = response.data;
			});
	}

	function getFuncoes() {
		Funcao.getFuncoes()
			.then(function(response) {
				vm.funcoes = response.data;
			});
	}
	$scope.insertContrato = function() {
		Contrato.insertContrato(vm.contrato)
			.then(function() {
				toastr.success('Contrato salvo com sucesso.');
				$state.go('dashboard.contratos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.contratoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	vm.popupInicioVigencia = {
		opened: false
	};

	vm.popupFimVigencia = {
		opened: false
	};

	$scope.openInicioVigencia = function() {
		vm.popupInicioVigencia.opened = true;
	}

	$scope.openFimVigencia = function() {
		vm.popupFimVigencia.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	$scope.addModuloProduto = function() {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_modulo.html',
			controller: 'ContratoModuloProdutoEditCtrl',
			scope: $scope,
			resolve: {
				modulo_produto: {
					funcoes_ids: []
				}
			}
		});

		modalInstance.result.then(function(modulo_produto) {
			console.log(modulo_produto);
			vm.contrato.modulos_produtos.push(modulo_produto);
		});
	}

	$scope.editModuloProduto = function(index) {
		var modalInstance = $uibModal.open({
			templateUrl: '/views/contratos/_modulo.html',
			controller: 'ContratoModuloProdutoEditCtrl',
			scope: $scope,
			resolve: {
				modulo_produto: vm.contrato.modulos_produtos[index]
			}
		});

		modalInstance.result.then(function(modulo_produto) {
			vm.contrato.modulos_produtos[index] = modulo_produto;
		});
	}

	$scope.deleteModuloProduto = function(index) {
		vm.contrato.modulos_produtos.splice(index, 1);
	}

	$scope.modulosDisponiveis = function(moduloAtual) {
		var modulosDisponiveis = [];
		var modulosUsados = [];

		angular.forEach(vm.contrato.modulos_produtos, function(modulo_produto) {
			modulosUsados.push(modulo_produto.modulo.id);
		})

		angular.forEach(vm.modulos_produtos, function(modulo_produto) {
			if (modulosUsados.indexOf(modulo_produto.id) < 0 ||
				(moduloAtual.modulo && moduloAtual.modulo.id == modulo_produto.id)) {
				modulosDisponiveis.push(modulo_produto);
			}
		});

		return modulosDisponiveis;
	}

	$scope.$watch('vm.contrato.fim_vigencia', function(newVal) {
		if (!vm.contrato) {
			return false;
		}

		if (newVal) {
			var fimVigencia = (new Date(newVal)).getTime();
			var hoje = (new Date()).getTime();
			vm.contrato.status = vm.contrato.renova_automaticamente == 'N' && fimVigencia > hoje ? 'I' : 'A';
		} else {
			vm.contrato.status = 'A';
		}
	});

	$scope.$watch('vm.contrato.renova_automaticamente', function(newVal) {
		if (!vm.contrato) {
			return false;
		}

		if (newVal) {
			var fimVigencia = (new Date(vm.contrato.fim_vigencia)).getTime();
			var hoje = (new Date()).getTime();
			vm.contrato.status = newVal == 'N' && fimVigencia > hoje ? 'I' : 'A';
		} else {
			vm.contrato.status = 'A';
		}
	});
	
	$scope.refreshProgramadores = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 2)
			.then(function(response) {
				vm.programadores = response.data.items;
			});
	}
}]);