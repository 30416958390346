app.controller('PrioridadeNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Prioridade', function($scope, $state, $stateParams, toastr, Prioridade) {
	var vm = this;

	$scope.insertPrioridade = function() {
		Prioridade.insertPrioridade(vm.prioridade)
			.then(function() {
				toastr.success('Prioridade salva com sucesso.');
				$state.go('dashboard.prioridades.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.prioridadeForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);