app.controller('ClassificacaoEditCtrl', ['$scope', '$location', '$stateParams', 'toastr', 'Classificacao', 'CentroCusto', 'Divisao', function($scope, $location, $stateParams, toastr, Classificacao, CentroCusto, Divisao) {
	var vm = this;
	
	vm.classificacaoId = $stateParams.classificacaoId;

	getCentrosCusto();
	getDivisoes();
    getClassificacao();

	function getClassificacao() {
        Classificacao.getClassificacao(vm.classificacaoId)
			.success(function(response) {
				vm.classificacao = response;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getDivisoes(){
		Divisao.getDivisoes()
			.then(function(response) {
				vm.divisoes = response.data;
			});
	}

	$scope.updateClassificacao = function() {
        Classificacao.updateClassificacao(vm.classificacao)
			.then(function() {
				toastr.success('Classificação alterada com sucesso.');
				$location.path('/classificacao');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.classificacaoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);