app.factory('Banco', ['$http', function($http){
	return {
		getBancos: function() {
			return $http.get('/api/bancos');
		},
		getBanco: function(id) {
			return $http.get('/api/bancos/' + id);	
		},
		insertBanco: function (bancoData) {
			return $http.post('/api/bancos', bancoData);
		},
		updateBanco: function (bancoData) {
			return $http.put('/api/bancos/' + bancoData.id, bancoData);
		},
		deleteBanco: function(id) {
			return $http.delete('/api/bancos/' + id);
		}
	};
}])