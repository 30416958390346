app.directive('pdfDownload', ['$window', function ($window) {
	return {
		restrict: 'E',
		templateUrl: '/views/pdf-download.html',
		scope: {
			params: '='
		},
		transclude: true,
		link: function (scope, element, attrs) {
			var anchor = element.children()[0];

			scope.$on('download-start', function() {
				$(anchor).attr('disabled', 'disabled');
			});

			scope.$on('downloaded', function(event, data) {
				$(anchor).removeAttr('disabled');
				$window.open('data:application/pdf;base64,' + data, '_blank');
			});
		},
		controller: ['$scope', '$attrs', '$http', function($scope, $attrs, $http) {
			$scope.downloadPdf = function() {
				$scope.$emit('download-start');
				$http.post($attrs.url, $scope.params).then(function(response) {
					$scope.$emit('downloaded', response.data);
				});
			};
		}]
	};
}])