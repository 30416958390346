app.controller('StatusListCtrl', ['$scope', 'Status', 'toastr', 'SweetAlert', '$state', function($scope, Status, toastr, SweetAlert, $state) {
	$scope.statuses = [];
	$scope.statusesPerPage = 10;

	$scope.refreshStatuses = function() {
		Status.getStatuses()
			.then(function(response) {
				$scope.statuses = response.data;
			});
	}

	$scope.refreshStatuses();

	$scope.deleteStatus = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste status não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Status.deleteStatus(id)
					.then(function() {
						toastr.success('Status removido com sucesso.');
						$scope.refreshStatuses();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este status porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);