app.factory('Mensagem', ['$http', function($http){
	return {
		getMensagens: function() {
			return $http.get('/api/mensagens');
		},
		getMensagem: function(id) {
			return $http.get('/api/mensagens/' + id);	
		},
		insertMensagem: function (mensagemData, imagem) {
            var fd = new FormData();
            fd.append('mensagem', angular.toJson(mensagemData));
            fd.append('imagem', imagem);
			return $http.post('/api/mensagens', fd, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			});
		},
		updateMensagem: function (mensagemData, imagem) {
            var fd = new FormData();
            fd.append('mensagem', angular.toJson(mensagemData));
            fd.append('imagem', imagem);
			return $http.post('/api/mensagens/' + mensagemData.id, fd, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined,
					'X-HTTP-Method-Override': 'PUT'
				}
			});
		},
		deleteMensagem: function(id) {
			return $http.delete('/api/mensagens/' + id);
		},
		getMensagemAleatoria: function() {
			return $http.get('/api/mensagens/random');
		}
	};
}])