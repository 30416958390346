app.controller('CursoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Curso', function($scope, $state, $stateParams, toastr, Curso) {
    var vm = this;
    vm.anexo = '';
    vm.curso = {
        titulo: null,
        link_youtube: null,
        texto: ''
    }

    $scope.insertCurso = function() {
        Curso.insertCurso(vm.curso, vm.anexo)
            .then(function() {
                toastr.success('Curso salvo com sucesso.');
                $state.go('dashboard.cursos.list');
            })
            .catch(function(response) {
                vm.errorMessage = {};
                angular.forEach(response.data, function(messages, field) {
                    vm.cursoForm[field].$setValidity('server', false);
                    vm.errorMessage[field] = messages[0];
                });
            });
    }
}]);