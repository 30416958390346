app.directive('uiSelectRequired', [function () {
	return {
		restrict: 'A',
		require: 'ngModel',
		link: function (scope, element, attrs, ctrl) {
			ctrl.$validators.uiSelectRequired = function(modelValue, viewValue) {
				var determineVal;
				if (angular.isArray(modelValue)) {
					determineVal = modelValue;
				} else if (angular.isArray(viewValue)) {
					determineVal = viewValue;
				} else {
					return false;
				}

				return determineVal.length > 0;
			};
		}
	};
}])