app.controller('MotivoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Motivo', function($scope, $state, $stateParams, toastr, Motivo) {
	var vm = this;

	$scope.insertMotivo = function() {
		Motivo.insertMotivo(vm.motivo)
			.then(function() {
				toastr.success('Motivo salvo com sucesso.');
				$state.go('dashboard.motivos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.motivoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);