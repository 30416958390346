app.controller('UserListCtrl', ['$scope', 'User', 'toastr', 'SweetAlert', 'Auth', function($scope, User, toastr, SweetAlert, Auth) {
	$scope.currentUserId = Auth.getCurrentUser().id;
	$scope.users = [];

	$scope.contasPerPage = 10;

	getUsers();

	$scope.deleteUser = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste usuário não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				User.deleteUser(id)
					.then(function() {
						toastr.success('Usuário removido com sucesso.');
						getUsers();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este usuário porque ele está vinculado em outros registros.');
					});
			}
		});
	}

	function getUsers() {
		User.getUsers()
			.then(function(response) {
				$scope.users = response.data;
			});
	}
}]);