app.controller('DivisaoListCtrl', ['$scope', 'Divisao', 'toastr', 'SweetAlert', '$state', function($scope, Divisao, toastr, SweetAlert, $state) {
	$scope.divisoes = [];
	$scope.divisoesPerPage = 10;

	$scope.refreshDivisoes = function() {
		Divisao.getDivisoes()
			.then(function(response) {
				$scope.divisoes = response.data;
			});
	}

	$scope.refreshDivisoes();

	$scope.deleteDivisao = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta divisão não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Divisao.deleteDivisao(id)
					.then(function() {
						toastr.success('Divisão removida com sucesso.');
						$scope.refreshDivisoes();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta divisão porque ela está vinculada em outros registros.');
					});
			}
		});
	}
}]);