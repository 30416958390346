app.controller('DivisaoNewCtrl', ['$scope', '$location', '$stateParams', 'toastr', 'Divisao', 'CentroCusto', function($scope, $location, $stateParams, toastr, Divisao, CentroCusto) {
	var vm = this;

	$scope.insertDivisao = function() {
		Divisao.insertDivisao(vm.divisao)
			.then(function() {
				toastr.success('Divisão salva com sucesso.');
				$location.path('/divisoes');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.divisaoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);