app.controller('ContaListCtrl', ['$scope', 'Conta', 'toastr', 'SweetAlert', '$state', function($scope, Conta, toastr, SweetAlert, $state) {
	$scope.contas = [];
	$scope.totalContas = 0;
	$scope.contasPerPage = 10;
	$scope.searchText = '';
	getResultsPage(1, $scope.searchText);

	$scope.pagination = {
	    current: 1
	};

	$scope.refreshContas = function() {
		getResultsPage($scope.pagination.current, $scope.searchText);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.searchText);
	}

	$scope.deleteConta = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta ContaListCtrl não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {
                Conta.deleteConta(id)
					.then(function() {
						toastr.success('Conta removida com sucesso.');
						$scope.refreshContas();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este conta porque ele está vinculado em outros registros.');
					});
			}
		});
	}

	function getResultsPage(pageNumber, searchText) {
        Conta.getContas((pageNumber-1) * $scope.contasPerPage, $scope.contasPerPage, searchText)
			.then(function(response) {
				$scope.contas = response.data.items;
				$scope.totalContas = response.data.count;
			})
			.catch(function() {
				$state.go('dashboard.home');
			});
	}
}]);
