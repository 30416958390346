app.factory('Cargo', ['$http', function($http){
	return {
		getCargos: function() {
			return $http.get('/api/cargos');
		},
		getCargo: function(id) {
			return $http.get('/api/cargos/' + id);	
		},
		insertCargo: function (cargoData) {
			return $http.post('/api/cargos', cargoData);
		},
		updateCargo: function (cargoData) {
			return $http.put('/api/cargos/' + cargoData.id, cargoData);
		},
		deleteCargo: function(id) {
			return $http.delete('/api/cargos/' + id);
		}
	};
}])