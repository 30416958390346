app.controller('PessoaListCtrl', ['$scope', 'Pessoa', 'toastr', 'SweetAlert', '$state', 'Auth', function($scope, Pessoa, toastr, SweetAlert, $state, Auth) {
	$scope.pessoas = [];
	$scope.totalPessoas = 0;
	$scope.pessoasPerPage = 10;
	$scope.searchText = '';
	getResultsPage(1, $scope.searchText);

	$scope.pagination = {
	    current: 1
	};

	$scope.refreshPessoas = function() {
		getResultsPage($scope.pagination.current, $scope.searchText);
	}

	$scope.pageChanged = function(newPage) {
		getResultsPage(newPage, $scope.searchText);
	}

	$scope.deletePessoa = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta pessoa não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {
				var currentUser = Auth.getCurrentUser();
				Pessoa.deletePessoa(id, currentUser.conta_id)
					.then(function() {
						toastr.success('Pessoa removida com sucesso.');
						$scope.refreshPessoas();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta pessoa porque ela está vinculada em outros registros.');
					});
			}
		});
	}

	function getResultsPage(pageNumber, searchText) {
		Pessoa.getPessoas((pageNumber-1) * $scope.pessoasPerPage, $scope.pessoasPerPage, searchText, false, false, false, false, true)
			.then(function(response) {
				$scope.pessoas = response.data.items;
				$scope.totalPessoas = response.data.count;
			})
			.catch(function() {
				$state.go('dashboard.home');
			});
	}
}]);