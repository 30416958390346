app.controller('AccountCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'User', 'Auth', '$rootScope', 'Role', 'Conta', function($scope, $state, $stateParams, toastr, User, Auth, $rootScope, Role, Conta) {
	var vm = this;
	vm.currentUserId = Auth.getCurrentUser().id;

	getUser();
	// getRoles();
	// getContas();

	// function getRoles() {
	// 	Role.getRoles()
	// 		.then(function(response) {
	// 			vm.roles = response.data;
	// 		});
	// }
	//
	// function getContas() {
	// 	Conta.getContas(0, 0, '')
	// 		.then(function(response) {
	// 			vm.contas = response.data.items;
	// 		});
	// }

	function getUser() {
		User.getUser(vm.currentUserId)
			.success(function(response) {
				vm.user = response;
			});
	}

	$scope.updateUser = function() {
		User.updateUser(vm.user)
			.then(function() {
				Auth.setCurrentUser(vm.user);
				$rootScope.$broadcast('currentUserChanged');
				toastr.success('Conta alterada com sucesso.');
				$state.go('dashboard.home');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.userForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);
