app.controller('LoginDialogCtrl', ['$scope',  '$http', '$uibModalInstance', 'toastr', function ($scope, $http, $uibModalInstance, toastr) {
	$scope.continue = function() {
		$http.post('/api/auth', {email: $scope.email, password: $scope.password})
			.then(function(response) {
				$uibModalInstance.close(response.data.token);
			})
			.catch(function(response) {
				toastr.error(response.data.message);
			});
	};

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	}
}])
