app.controller('OrigemTransacaoNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'OrigemTransacao', function($scope, $state, $stateParams, toastr, OrigemTransacao) {
	var vm = this;

	$scope.insertOrigem = function() {
		OrigemTransacao.insertOrigem(vm.origem)
			.then(function() {
				toastr.success('Origem salva com sucesso.');
				$state.go('dashboard.origem_transacao.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.origemForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);