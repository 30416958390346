app.controller('NavbarCtrl', ['$scope', 'Auth', 'toastr', '$state', '$rootScope', function ($scope, Auth, toastr, $state, $rootScope) {
	$scope.currentUser = Auth.getCurrentUser();

	var token = Auth.getToken();
	var payload = Auth.parseToken(token);
	$scope.contaId = payload.fid;

	$rootScope.$on('currentUserChanged', function() {
		$scope.currentUser = Auth.getCurrentUser();
	})

	$scope.logout = function() {
		Auth.logout();
		toastr.success('Sessão finalizada com sucesso.');
		$state.go('login');
	}
}]);
