app.controller('PrioridadeListCtrl', ['$scope', 'Prioridade', 'toastr', 'SweetAlert', '$state', function($scope, Prioridade, toastr, SweetAlert, $state) {
	$scope.prioridades = [];
	$scope.prioridadesPerPage = 10;

	$scope.refreshPrioridades = function() {
		Prioridade.getPrioridades()
			.then(function(response) {
				$scope.prioridades = response.data;
			});
	}

	$scope.refreshPrioridades();

	$scope.deletePrioridade = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta prioridade não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Prioridade.deletePrioridade(id)
					.then(function() {
						toastr.success('Prioridade removida com sucesso.');
						$scope.refreshPrioridades();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta prioridade porque ela está vinculada em outros registros.');
					});
			}
		});
	}
}]);