app.controller('BrindeNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Brinde', 'Pessoa', function($scope, $state, $stateParams, toastr, Brinde, Pessoa) {
	var vm = this;

	vm.popupDataEntrega = {
		opened: false
	};

	$scope.openDataEntrega = function() {
		vm.popupDataEntrega.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social', 0, true)
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	$scope.insertBrinde = function() {
		Brinde.insertBrinde(vm.brinde)
			.then(function() {
				toastr.success('Brinde salvo com sucesso.');
				$state.go('dashboard.brindes.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.brindeForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);