app.controller('PrioridadeEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Prioridade', function($scope, $state, $stateParams, toastr, Prioridade) {
	var vm = this;
	vm.prioridadeId = $stateParams.prioridadeId;

	getPrioridade();

	function getPrioridade() {
		Prioridade.getPrioridade(vm.prioridadeId)
			.success(function(response) {
				vm.prioridade = response;
			});
	}

	$scope.updatePrioridade = function() {
		Prioridade.updatePrioridade(vm.prioridade)
			.then(function() {
				toastr.success('Prioridade alterada com sucesso.');
				$state.go('dashboard.prioridades.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.prioridadeForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);