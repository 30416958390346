app.factory('Role', ['$http', function($http){
	return {
		getRoles: function() {
			return $http.get('/api/roles');
		},
		getRole: function(id) {
			return $http.get('/api/roles/' + id);	
		},
		insertRole: function (roleData) {
			return $http.post('/api/roles', roleData);
		},
		updateRole: function (roleData) {
			return $http.put('/api/roles/' + roleData.id, roleData);
		},
		deleteRole: function(id) {
			return $http.delete('/api/roles/' + id);
		}
	};
}])