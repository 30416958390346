app.factory('ModuloProduto', ['$http', function($http){
	return {
		getModulosProdutos: function() {
			return $http.get('/api/modulos-produtos');
		},
		getModuloProduto: function(id) {
			return $http.get('/api/modulos-produtos/' + id);	
		},
		insertModuloProduto: function (moduloProdutoData) {
			return $http.post('/api/modulos-produtos', moduloProdutoData);
		},
		updateModuloProduto: function (moduloProdutoData) {
			return $http.put('/api/modulos-produtos/' + moduloProdutoData.id, moduloProdutoData);
		},
		deleteModuloProduto: function(id) {
			return $http.delete('/api/modulos-produtos/' + id);
		}
	};
}])