app.factory('Produto', ['$http', function($http){
	return {
		getProdutos: function() {
			return $http.get('/api/produtos');
		},
		getModulosByProduto: function(produtoId) {
			return $http.get('/api/produtos/' + produtoId + '/modulos');
		},
		getProduto: function(id) {
			return $http.get('/api/produtos/' + id);	
		},
		insertProduto: function (produtoData) {
			return $http.post('/api/produtos', produtoData);
		},
		updateProduto: function (produtoData) {
			return $http.put('/api/produtos/' + produtoData.id, produtoData);
		},
		deleteProduto: function(id) {
			return $http.delete('/api/produtos/' + id);
		}
	};
}])