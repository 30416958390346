app.controller('StatusEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Status', function($scope, $state, $stateParams, toastr, Status) {
	var vm = this;
	vm.statusId = $stateParams.statusId;

	getStatus();

	function getStatus() {
		Status.getStatus(vm.statusId)
			.success(function(response) {
				vm.status = response;
			});
	}

	$scope.updateStatus = function() {
		Status.updateStatus(vm.status)
			.then(function() {
				toastr.success('Status alterado com sucesso.');
				$state.go('dashboard.statuses.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.statusForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);