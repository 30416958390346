app.controller('ModuloProdutoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'ModuloProduto', 'Funcao', function($scope, $state, $stateParams, toastr, ModuloProduto, Funcao) {
	var vm = this;
	vm.moduloProdutoId = $stateParams.moduloProdutoId;
	vm.funcoes = [];

	getFuncoes();
	getModuloProduto();

	function getFuncoes() {
		Funcao.getFuncoes()
			.then(function(response) {
				vm.funcoes = response.data;
			});
	}

	function getModuloProduto() {
		ModuloProduto.getModuloProduto(vm.moduloProdutoId)
			.success(function(response) {
				vm.moduloProduto = response;
			});
	}

	$scope.updateModuloProduto = function() {
		ModuloProduto.updateModuloProduto(vm.moduloProduto)
			.then(function() {
				toastr.success('Módulo de produto alterado com sucesso.');
				$state.go('dashboard.modulos_produtos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.moduloProdutoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);