app.factory('User', ['$http', function($http){
	return {
		getUsers: function() {
			return $http.get('/api/users');
		},
		getUser: function(id) {
			return $http.get('/api/users/' + id);	
		},
		insertUser: function (userData) {
			return $http.post('/api/users', userData);
		},
		updateUser: function (userData) {
			return $http.put('/api/users/' + userData.id, userData);
		},
		deleteUser: function(id) {
			return $http.delete('/api/users/' + id);
		}
	};
}])