app.controller('HomeCtrl', ['$scope', 'Mensagem', function ($scope, Mensagem) {

	getMensagem();

	function getMensagem() {
		Mensagem.getMensagemAleatoria()
			.then(function(response) {
				$scope.mensagem = response.data;
			});
	}
}]);
