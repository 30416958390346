app.factory('Setor', ['$http', function($http){
	return {
		getSetores: function() {
			return $http.get('/api/setores');
		},
		getSetor: function(id) {
			return $http.get('/api/setores/' + id);	
		},
		insertSetor: function (setorData) {
			return $http.post('/api/setores', setorData);
		},
		updateSetor: function (setorData) {
			return $http.put('/api/setores/' + setorData.id, setorData);
		},
		deleteSetor: function(id) {
			return $http.delete('/api/setores/' + id);
		}
	};
}])