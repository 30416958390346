app.controller('PessoaExistenteModalCtrl', ['$scope', '$state', '$stateParams', 'pessoa', '$uibModalInstance', 'Auth', 'Pessoa', function($scope, $state, $stateParams, pessoa, $uibModalInstance, Auth, Pessoa) {
	$scope.pessoa = pessoa;

    $scope.irParaCadastro = function() {
        $scope.cancel();
    	$state.go('dashboard.pessoas.edit', {pessoaId: pessoa.id});
    }

    $scope.cancelarCadastro = function() {
        $scope.cancel();
    	$state.go('dashboard.pessoas.list');
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }
    
    $scope.vincularEIrPara = function(){
    	var user = Auth.getCurrentUser();
    	Pessoa.vincularPessoaConta(user.conta_id, pessoa)
    	.then(function(response){
    	 	$state.go('dashboard.pessoas.edit', {pessoaId: pessoa.id});
    	});
    }
}]);