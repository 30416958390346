app.controller('RoleNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Role', 'Permission', function($scope, $state, $stateParams, toastr, Role, Permission) {
	var vm = this;
	vm.role = {
		permissions_ids: []
	};
	vm.permissions = [];

	getPermissions();

	function getPermissions() {
		Permission.getPermissions()
			.success(function(results) {
				var permissions = {};
				for (var i = 0; i < results.length; i++) {
					var result = results[i];
					if (!permissions[result.category])
						permissions[result.category] = [];
					permissions[result.category].push(result);
				}
				angular.forEach(permissions, function(perms, category) {
					var item = { label: category, value: category.toLowerCase(), children: [] };
					for (var i = 0; i < perms.length; i++) {
						var perm = perms[i];
						item.children.push({
							label: perm.human_name,
							value: perm.name,
							id: perm.id
						});
					}
					vm.permissions.push(item);
				});
			});
	}

	$scope.insertRole = function() {
		Role.insertRole(vm.role)
			.then(function() {
				toastr.success('Perfil salvo com sucesso.');
				$state.go('dashboard.roles.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.roleForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.changeCallback = function(node, isSelected, tree) {
		if (node.children && node.children.length > 0) {
			angular.forEach(node.children, function(childNode) {
				$scope.changeCallback(childNode, isSelected, tree);
			});
			return false;
		}
		if (isSelected) {
			vm.role.permissions_ids.push(node.id);
		} else {
			var index = vm.role.permissions_ids.indexOf(node.id);
			vm.role.permissions_ids.splice(index, 1);
		}
	}
}]);