app.controller('SetorEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Setor', function($scope, $state, $stateParams, toastr, Setor) {
	var vm = this;
	vm.setorId = $stateParams.setorId;

	getSetor();

	function getSetor() {
		Setor.getSetor(vm.setorId)
			.success(function(response) {
				vm.setor = response;
			});
	}

	$scope.updateSetor = function() {
		Setor.updateSetor(vm.setor)
			.then(function() {
				toastr.success('Setor alterado com sucesso.');
				$state.go('dashboard.setores.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.setorForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);