app.controller('MotivoListCtrl', ['$scope', 'Motivo', 'toastr', 'SweetAlert', '$state', function($scope, Motivo, toastr, SweetAlert, $state) {
	$scope.motivos = [];
	$scope.motivosPerPage = 10;

	$scope.refreshMotivos = function() {
		Motivo.getMotivos()
			.then(function(response) {
				$scope.motivos = response.data;
			});
	}

	$scope.refreshMotivos();

	$scope.deleteMotivo = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste motivo não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				Motivo.deleteMotivo(id)
					.then(function() {
						toastr.success('Motivo removido com sucesso.');
						$scope.refreshMotivos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este motivo porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);