app.controller('LancamentoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Lancamento', 'Classificacao', 'CentroCusto', 'Banco', 'FormaPagamento', 'Pessoa', 'Contrato', 'Conta', 'OrigemTransacao', 'SweetAlert', function($scope, $state, $stateParams, toastr, Lancamento, Classificacao, CentroCusto, Banco, FormaPagamento, Pessoa, Contrato, Conta, OrigemTransacao, SweetAlert) {
	var vm = this;

	vm.lancamentoId = $stateParams.lancamentoId;
	
	//Se tem data de pagamento o status é pago e desabilitado para edição
	vm.statusReadOnly = false;
	//Se o status é pago a data de pagamento é obrigatória
	vm.hasPaid = false;

	getBancos();
    getClassificacaos();
	getCentrosCusto();
	getFormasPagamento();
	getLancamento();
	getOrigemTransacao();

	if ($scope.ehAdministrador()) {
		getContas();
	}

	function getLancamento() {
		Lancamento.getLancamento(vm.lancamentoId)
			.success(function(response) {
				vm.lancamento = response;
				if(vm.lancamento.despesa_fixa == 1){
					vm.lancamento.despesa_fixa = true;
				}else{
					vm.lancamento.despesa_fixa = false;
				}
				statusReadOnly((vm.lancamento.data_pagamento ? vm.lancamento.data_pagamento : ''), '');
				dtPagamentoRequired();
			});
	}

	function getClassificacaos(){
        Classificacao.getClassificacaos()
			.then(function(response) {
				vm.classificacaos = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				vm.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}

	function getContas(){
        Conta.getContas(0, 0, '', 'id,razao_social')
			.then(function(response) {
				vm.contas = response.data.items;
			});
	}
	
	function getOrigemTransacao(){
		OrigemTransacao.getOrigens()
			.then(function(response){
				vm.origens_transacao = response.data;
			});
	}

	vm.popupDataEmissao = {
		opened: false
	};

	vm.popupDataVencimento = {
		opened: false
	};

	vm.popupDataPagamento = {
		opened: false
	};

	$scope.openDataEmissao = function() {
		vm.popupDataEmissao.opened = true;
	}

	$scope.openDataVencimento = function() {
		vm.popupDataVencimento.opened = true;
	}

	$scope.openDataPagamento = function() {
		vm.popupDataPagamento.opened = true;
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	$scope.refreshContratos = function(search) {
		Contrato.getContratos(1, search)
			.then(function(response) {
				vm.contratos = response.data.items;
			});
	}

	$scope.updateLancamento = function() {
		Lancamento.updateLancamento(vm.lancamento)
			.then(function() {
				toastr.success('Lançamento alterada com sucesso.');
				$state.go('dashboard.lancamentos.list', {
					'pessoaId': $stateParams.pessoaId,
					'searchText': $stateParams.searchText,
					'columns': $stateParams.columns,
					'sortType': $stateParams.sortType,
					'sortReverse': $stateParams.sortReverse,
					'filtros': $stateParams.filtros
				});
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.lancamentoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.updateValorLiquido = function() {
		vm.lancamento.valor_liquido = vm.lancamento.valor_bruto + vm.lancamento.juros - vm.lancamento.desconto;
	}

	$scope.preencherCentroCusto = function() {
		if (vm.classificacaos && vm.lancamento.classificacao_id) {
			for(var i = 0; i < vm.classificacaos.length; i++){
				if(vm.classificacaos[i].id == vm.lancamento.classificacao_id && vm.classificacaos[i].centro_custo_id){
					vm.lancamento.centro_custo_id = vm.classificacaos[i].centro_custo_id;
					return;
				}
			}
		}
	}

	$scope.editContrato = function(index) {
		vm.editingContrato = angular.copy(vm.lancamento.contratos[index], {});
		vm.editingContratoIndex = index;
	}

	$scope.saveContrato = function() {
		if (vm.editingContratoIndex >= 0) {
			vm.lancamento.contratos[vm.editingContratoIndex] = vm.editingContrato;
			vm.editingContratoIndex = null;
		} else {
			vm.lancamento.contratos.push(vm.editingContrato);
		}
		vm.editingContrato = {};
		vm.contratoForm.$setPristine();
	}

	$scope.deleteContrato = function(index) {
		if (vm.lancamento.contratos[index].id) {
			vm.lancamento.contratos[index]._destroy = true;
		} else {
			vm.lancamento.contratos.splice(index, 1);
		}
	}

	$scope.somaContratos = function() {
		if (!vm.lancamento) {
			return 0;
		}

		var soma = 0;
		angular.forEach(vm.lancamento.contratos, function(contrato) {
			if (!contrato._destroy) {
				soma += parseFloat(contrato.valor);
			}
		});

		return soma;
	}

	$scope.preencherDataVencimento = function() {
		return false;
	}
	
	/**
	 * Controla a ativação/desativação do campo status
	 */
	$scope.statusReadOnly = function(newValue, oldValue){
		statusReadOnly((newValue ? newValue : ''), (oldValue ? oldValue : ''));
	}
	
	function statusReadOnly(newValue, oldValue){
		if(newValue != oldValue){
			vm.statusReadOnly = (newValue ? true : false);
			if(vm.statusReadOnly){
				vm.lancamento.status = 'E';
				dtPagamentoRequired();
			}else{
				vm.lancamento.status = 'P';
				dtPagamentoRequired();
			}
		}
	}
	
	$scope.dtPagamentoRequired = function(){
		dtPagamentoRequired();
	}
	
	$scope.boolToStr = function(value) {
		return value ? 'Sim' : 'Não';
	}
	
	function dtPagamentoRequired(){
		if(vm.lancamento.status == 'E'){
			vm.hasPaid = true;
		}else{
			vm.hasPaid = false;
		}
	}
	
}]);
