app.controller('FluxoCaixaFuturoCtrl', ['$scope', 'Banco', 'Classificacao', 'CentroCusto', 'Pessoa', 'Auth', function ($scope, Banco, Classificacao, CentroCusto, Pessoa, Auth) {
	var anoAtual = (new Date()).getFullYear();

	$scope.anos = [];
	for (var a = anoAtual; a >= anoAtual-10; a--) {
		$scope.anos.push(a);
	}

	$scope.filtros = {
		ano: anoAtual
	};

	getBancos();
    getClassificacaos();
	getCentrosCusto();

	function getClassificacaos(){
        Classificacao.getClassificacaos()
			.then(function(response) {
				$scope.classificacaos = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				$scope.centros_custo = response.data;
			});
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/fluxo-caixa-futuro?token=' + token + '&' + $.param($scope.filtros);
	}
}]);