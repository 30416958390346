app.controller('CursoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Curso', 'CentroCusto', function($scope, $state, $stateParams, toastr, Curso, CentroCusto) {
	var vm = this;
	vm.anexo = '';
	vm.cursoId = $stateParams.cursoId;

	getCurso();

	function getCurso() {
		Curso.getCurso(vm.cursoId)
			.success(function(response) {
				vm.curso = response;
			});
	}

	$scope.updateCurso = function() {
		Curso.updateCurso(vm.curso, vm.anexo)
			.then(function() {
				toastr.success('Curso alterado com sucesso.');
				$state.go('dashboard.cursos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.cursoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);