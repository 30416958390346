app.controller('ContaNewCtrl', ['$scope', '$location', 'toastr', 'Conta', 'Setor', 'Cargo', 'RamoAtuacao', '$uibModal', 'Cep', 'Auth', 'FormaPagamento', 'Profissao', '$http', 'Plano', function($scope, $location, toastr, Conta, Setor, Cargo, RamoAtuacao, $uibModal, Cep, Auth, FormaPagamento, Profissao, $http, Plano) {
	var vm = this;
	vm.conta = {
		perfil: 'PJ',
		ativo: true,
		contatos: {},
		municipio_nfse: {
			nome: 'PORTO ALEGRE',
			uf: 'RS',
			codigo_ibge: '4314902'
		}
	};

	vm.meses = {
		1: 'Janeiro',
		2: 'Fevereiro',
		3: 'Março',
		4: 'Abril',
		5: 'Maio',
		6: 'Junho',
		7: 'Julho',
		8: 'Agosto',
		9: 'Setembro',
		10: 'Outubro',
		11: 'Novembro',
		12: 'Dezembro',
	};

  vm.naturezaOperacao = {
    '1': 'Tributação no município',
    '2': 'Tributação fora do município',
    '3': 'Isenção',
    '4': 'Imune',
    '5': 'Exigibilidade suspensa por decisão judicial',
    '6': 'Exigibilidade suspensa por procedimento administrativo'
  };

  vm.regimeEspecialTributacao = {
    '1': 'Microempresa Municipal',
    '2': 'Estimativa',
    '3': 'Sociedade de Profissionais',
    '4': 'Cooperativa',
    '5': 'MEI do Simples Nacional',
    '6': 'ME ou EPP do Simples Nacional'
  };

	getSetores();
	getCargos();
	getRamosAtuacao();
	getFormasPagamento();
	getProfissoes();
	getServicos();
	getPlanos();

	function getSetores(){
		Setor.getSetores()
			.then(function(response) {
				vm.setores = response.data;
			});
	}

	function getRamosAtuacao(){
		RamoAtuacao.getRamosAtuacao()
			.then(function(response) {
				vm.ramos_atuacao = response.data;
			});
	}

	function getCargos(){
		Cargo.getCargos()
			.then(function(response) {
				vm.cargos = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}

	function getProfissoes(){
		Profissao.getProfissoes()
			.then(function(response) {
				vm.profissoes = response.data;
			});
	}

	function getPlanos(){
		Plano.getPlanos()
			.then(function(response) {
				vm.planos = response.data;
			});
	}

	function getServicos(){
	  $http.get('/servicos.json')
	    .then(function(response) {
	      vm.servicos = response.data;
	    });
	}

	$scope.insertConta = function() {
		// console.log(vm);
        Conta.insertConta(vm.conta)
			.then(function() {
				toastr.success('Conta salva com sucesso.');
				$location.path('/contas');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.contaForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.boolToStr = function(value) {
		return value ? 'Sim' : 'Não';
	}

	$scope.buscarCep = function() {
		var cep = vm.conta.endereco.cep;

		if (!cep) {
			return false;
		};

		Cep.search(cep)
			.then(function(response) {
				var dados = response.data;
				if (dados.tipo == 1) {
					vm.conta.endereco.uf = dados.uf;
					vm.conta.endereco.cidade = dados.cidade;
					vm.conta.endereco.logradouro = dados.logradouro;
					vm.conta.endereco.bairro = dados.bairro;
				} else if (dados.tipo == 2) {
					vm.conta.endereco.uf = dados.uf;
					vm.conta.endereco.cidade = dados.cidade;
				} else {
					toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
				}
			}, function() {
				toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
			});
	}
}]);
