app.controller('TipoRelacionamentoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'TipoRelacionamento', function($scope, $state, $stateParams, toastr, TipoRelacionamento) {
	var vm = this;
	vm.tipoRelacionamentoId = $stateParams.tipoRelacionamentoId;

	getTipoRelacionamento();

	function getTipoRelacionamento() {
		TipoRelacionamento.getTipoRelacionamento(vm.tipoRelacionamentoId)
			.success(function(response) {
				vm.tipoRelacionamento = response;
			});
	}

	$scope.updateTipoRelacionamento = function() {
		TipoRelacionamento.updateTipoRelacionamento(vm.tipoRelacionamento)
			.then(function() {
				toastr.success('Tipo de relacionamento alterado com sucesso.');
				$state.go('dashboard.tipos_relacionamento.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.tipoRelacionamentoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);