app.factory('Curso', ['$http', function($http){
	return {
		getCursos: function() {
			return $http.get('/api/cursos');
		},
		getCurso: function(id) {
			return $http.get('/api/cursos/' + id);
		},
		insertCurso: function (cursoData, anexo) {
            var fd = new FormData();
            fd.append('curso', angular.toJson(cursoData));
            fd.append('anexo', anexo);
			return $http.post('/api/cursos', fd, {
				transformRequest: angular.identity,
				headers: {'Content-Type': undefined}
			});
		},
		updateCurso: function (cursoData, anexo) {
            var fd = new FormData();
            fd.append('curso', angular.toJson(cursoData));
            fd.append('anexo', anexo);
			return $http.post('/api/cursos/' + cursoData.id, fd, {
				transformRequest: angular.identity,
				headers: {
					'Content-Type': undefined,
					'X-HTTP-Method-Override': 'PUT'
				}
			});
		},
		deleteCurso: function(id) {
			return $http.delete('/api/cursos/' + id);

		},
	};
}])