app.controller('ProfissaoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Profissao', function($scope, $state, $stateParams, toastr, Profissao) {
	var vm = this;
	vm.profissaoId = $stateParams.profissaoId;

	getProfissao();

	function getProfissao() {
		Profissao.getProfissao(vm.profissaoId)
			.success(function(response) {
				vm.profissao = response;
			});
	}

	$scope.updateProfissao = function() {
		Profissao.updateProfissao(vm.profissao)
			.then(function() {
				toastr.success('Profissão alterada com sucesso.');
				$state.go('dashboard.profissoes.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.profissaoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);