app.controller('ProducaoCtrl', ['$scope', 'User', 'Pessoa', 'Auth', function ($scope, User, Pessoa, Auth) {
	$scope.filtros = {};

	$scope.popupMes = {
		opened: false
	};

	$scope.openMes = function() {
		$scope.popupMes.opened = true;
	}

	getUsers();

	function getUsers(){
		User.getUsers()
			.then(function(response) {
				$scope.users = response.data;
			});
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.getUrl = function() {
		var token = Auth.getToken();
		return '/api/relatorios/producao?token=' + token + '&' + $.param($scope.filtros);
	}
}]);