app.factory('Prioridade', ['$http', function($http){
	return {
		getPrioridades: function() {
			return $http.get('/api/prioridades');
		},
		getPrioridade: function(id) {
			return $http.get('/api/prioridades/' + id);	
		},
		insertPrioridade: function (prioridadeData) {
			return $http.post('/api/prioridades', prioridadeData);
		},
		updatePrioridade: function (prioridadeData) {
			return $http.put('/api/prioridades/' + prioridadeData.id, prioridadeData);
		},
		deletePrioridade: function(id) {
			return $http.delete('/api/prioridades/' + id);
		}
	};
}])