app.controller('ContratoModuloProdutoEditCtrl', ['$scope', '$uibModalInstance', 'modulo_produto', '$filter', function($scope, $uibModalInstance, modulo_produto, $filter) {
	$scope.modulo_produto = angular.copy(modulo_produto, {});

	$scope.addFuncao = function() {
		$scope.modulo_produto.funcoes_ids.push($scope.modulo_produto.funcao.id);
		$scope.modulo_produto.funcao = null;
	}

	$scope.deleteFuncao = function(index) {
		$scope.modulo_produto.funcoes_ids.splice(index, 1);
	}

	$scope.funcoesDisponiveis = function(funcoes) {
		var funcoesDisponiveis = [];

		angular.forEach(funcoes, function(funcao) {
			if (!$scope.modulo_produto.funcoes_ids ||
				($scope.modulo_produto.funcoes_ids.indexOf(funcao.id) < 0 &&
				$scope.modulo_produto.funcoes_ids.indexOf('' + funcao.id + '') < 0)) {
				funcoesDisponiveis.push(funcao);
			}
		});

		return funcoesDisponiveis;
	}

	$scope.save = function() {
		$uibModalInstance.close($scope.modulo_produto);
	}

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	}

	$scope.descricaoFuncao = function(funcaoId, funcoes) {
		for (var i = 0; i < funcoes.length; i++) {
			if (funcoes[i].id == funcaoId) {
				return funcoes[i].descricao;
			}
		}
		return null;
	}
}]);