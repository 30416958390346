app.controller('ProdutoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Produto', 'Pessoa', 'ModuloProduto', function($scope, $state, $stateParams, toastr, Produto, Pessoa, ModuloProduto) {
	var vm = this;
	vm.produtoId = $stateParams.produtoId;

	getProduto();
	getModulosProdutos();

	function getProduto() {
		Produto.getProduto(vm.produtoId)
			.success(function(response) {
				vm.produto = response;
			});
	}

	function getModulosProdutos(){
		ModuloProduto.getModulosProdutos()
			.then(function(response) {
				vm.modulos_produtos = response.data;
			});
	}

	$scope.updateProduto = function() {
		Produto.updateProduto(vm.produto)
			.then(function() {
				toastr.success('Produto alterado com sucesso.');
				$state.go('dashboard.produtos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.produtoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);