app.factory('Divisao', ['$http', function($http){
	return {
		getDivisoes: function() {
			return $http.get('/api/divisoes');
		},
		getDivisao: function(id) {
			return $http.get('/api/divisoes/' + id);	
		},
		insertDivisao: function (divisaoData) {
			return $http.post('/api/divisoes', divisaoData);
		},
		updateDivisao: function (divisaoData) {
			return $http.put('/api/divisoes/' + divisaoData.id, divisaoData);
		},
		deleteDivisao: function(id) {
			return $http.delete('/api/divisoes/' + id);
		}
	};
}])