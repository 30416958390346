app.factory('Auth', ['$window', function ($window) {
	return {
		isAuthenticated: function() {
			var token = this.getToken();
			if (token) {
				var params = this.parseToken(token);
				return Math.round(new Date().getTime() / 1000) <= params.exp;
			} else {
				return false;
			}
		},
		parseToken: function(token) {
			var base64Url = token.split('.')[1];
			var base64 = base64Url.replace('-', '+').replace('_', '/');
			return JSON.parse($window.atob(base64));
		},
		saveToken: function(token) {
			$window.localStorage['jwtToken'] = token;
		},
		getToken: function() {
			return $window.localStorage['jwtToken'];
		},
		setCurrentUser: function(user) {
			$window.localStorage['currentUser'] = JSON.stringify(user);
		},
		getCurrentUser: function() {
			return JSON.parse($window.localStorage['currentUser']);
		},
		setPermissions: function(user) {
			$window.localStorage['permissions'] = JSON.stringify(user);
		},
		getPermissions: function() {
			return JSON.parse($window.localStorage['permissions']);
		},
		hasPermission: function(permission) {
			return this.getPermissions().indexOf(permission) > -1;
		},
		logout: function() {
			$window.localStorage.removeItem('jwtToken');
			$window.localStorage.removeItem('currentUser');
			$window.localStorage.removeItem('permissions');
		}
	};
}]);
