app.controller('TipoRelacionamentoListCtrl', ['$scope', 'TipoRelacionamento', 'toastr', 'SweetAlert', '$state', function($scope, TipoRelacionamento, toastr, SweetAlert, $state) {
	$scope.tiposRelacionamento = [];
	$scope.tiposRelacionamentoPerPage = 10;

	$scope.refreshTiposRelacionamento = function() {
		TipoRelacionamento.getTiposRelacionamento()
			.then(function(response) {
				$scope.tiposRelacionamento = response.data;
			});
	}

	$scope.refreshTiposRelacionamento();

	$scope.deleteTipoRelacionamento = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção deste tipo de relacionamento não pode ser desfeita.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {			
				TipoRelacionamento.deleteTipoRelacionamento(id)
					.then(function() {
						toastr.success('Tipo de relacionamento removido com sucesso.');
						$scope.refreshTiposRelacionamento();
					})
					.catch(function() {
						toastr.error('Não foi possível remover este tipo de relacionamento porque ele está vinculada em outros registros.');
					});
			}
		});
	}
}]);