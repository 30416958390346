app.controller('FormaPagamentoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'FormaPagamento', function($scope, $state, $stateParams, toastr, FormaPagamento) {
	var vm = this;
	vm.formaPagamentoId = $stateParams.formaPagamentoId;

	getFormaPagamento();

	function getFormaPagamento() {
		FormaPagamento.getFormaPagamento(vm.formaPagamentoId)
			.success(function(response) {
				vm.formaPagamento = response;
			});
	}

	$scope.updateFormaPagamento = function() {
		FormaPagamento.updateFormaPagamento(vm.formaPagamento)
			.then(function() {
				toastr.success('Forma de pagamento alterada com sucesso.');
				$state.go('dashboard.formas_pagamento.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.formaPagamentoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);