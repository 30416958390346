app.controller('MensagemNewCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Mensagem', function($scope, $state, $stateParams, toastr, Mensagem) {
	var vm = this;
	vm.imagem = '';
	vm.mensagem = {
		categoria: null,
		pagina_inicial: 'N',
		mensagem: ''
	}

	$scope.insertMensagem = function() {
		Mensagem.insertMensagem(vm.mensagem, vm.imagem)
			.then(function() {
				toastr.success('Mensagem salva com sucesso.');
				$state.go('dashboard.mensagens.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.mensagemForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);