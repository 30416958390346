app.controller('MonthPickerCtrl', ['$scope', '$uibModalInstance', 'Banco', function($scope, $uibModalInstance, Banco) {
	$scope.mes = '';

	getBancos();

	$scope.meses = {
		1: "Janeiro",
		2: "Fevereiro",
		3: "Março",
		4: "Abril",
		5: "Maio",
		6: "Junho",
		7: "Julho",
		8: "Agosto",
		9: "Setembro",
		10: "Outubro",
		11: "Novembro",
		12: "Dezembro"
	};

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				$scope.bancos = response.data;
			});
	}

    $scope.save = function() {
        $uibModalInstance.close({
        	mes: $scope.mes,
        	banco: $scope.banco_id
        });
    }

    $scope.cancel = function() {
        $uibModalInstance.dismiss('cancel');
    }
}]);