app.controller('LancamentoNewCtrl', ['$scope', '$state', 'toastr', 'Lancamento', 'Classificacao', 'CentroCusto', 'Banco', 'FormaPagamento', 'Pessoa', 'Contrato', 'OrigemTransacao', '$stateParams', 'SweetAlert', 'Auth', function($scope, $state, toastr, Lancamento, Classificacao, CentroCusto, Banco, FormaPagamento, Pessoa, Contrato, OrigemTransacao, $stateParams, SweetAlert, Auth) {
	var vm = this;

	vm.lancamento = {
		natureza: 'PJ',
		tipo: 'D',
		status: 'P',
		valor_bruto: 0.0,
		desconto: 0.0,
		juros: 0.0,
		valor_liquido: 0.0,
		data_emissao: new Date(),
		contratos: []
	};
	
	//Se tem data de pagamento o status é pago e desabilitado para edição
	vm.statusReadOnly = false;
	//Se o status é pago a data de pagamento é obrigatória
	vm.hasPaid = false;
	
	$scope.pessoaId = $stateParams.pessoaId;
	if ($scope.pessoaId) {
		Pessoa.getPessoa($scope.pessoaId)
			.then(function(response) {
				vm.lancamento.pessoa = response.data;
			});
	}

    getClassificacaos();
	getBancos();
	getCentrosCusto();
	getFormasPagamento();
	getConta();
	getOrigemTransacao();

	function getClassificacaos(){
        Classificacao.getClassificacaos()
			.then(function(response) {
				vm.classificacaos = response.data;
			});
	}

	function getBancos(){
		Banco.getBancos()
			.then(function(response) {
				vm.bancos = response.data;
			});
	}

	function getCentrosCusto(){
		CentroCusto.getCentrosCusto()
			.then(function(response) {
				vm.centros_custo = response.data;
			});
	}

	function getFormasPagamento(){
		FormaPagamento.getFormasPagamento()
			.then(function(response) {
				vm.formas_pagamento = response.data;
			});
	}
	
	function getConta(){
		var currentUser = Auth.getCurrentUser();
		vm.lancamento.conta_id = currentUser.conta_id;
	}
	
	function getOrigemTransacao(){
		OrigemTransacao.getOrigens()
			.then(function(response){
				vm.origens_transacao = response.data;
			});
	}

	$scope.insertLancamento = function() {
		Lancamento.insertLancamento(vm.lancamento)
			.then(function() {
				toastr.success('Lançamento salvo com sucesso.');
				$state.go('dashboard.lancamentos.list', {
					'pessoaId': $stateParams.pessoaId,
					'searchText': $stateParams.searchText,
					'columns': $stateParams.columns,
					'sortType': $stateParams.sortType,
					'sortReverse': $stateParams.sortReverse,
					'filtros': $stateParams.filtros
				});
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.lancamentoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}

	$scope.refreshContratos = function(search) {
		Contrato.getContratos(1, search)
			.then(function(response) {
				vm.contratos = response.data.items;
			});
	}

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				vm.pessoas = response.data.items;
			});
	}

	vm.popupDataEmissao = {
		opened: false
	};

	vm.popupDataVencimento = {
		opened: false
	};

	vm.popupDataPagamento = {
		opened: false
	};

	$scope.openDataEmissao = function() {
		vm.popupDataEmissao.opened = true;
	}

	$scope.openDataVencimento = function() {
		vm.popupDataVencimento.opened = true;
	}

	$scope.openDataPagamento = function() {
		vm.popupDataPagamento.opened = true;
	}

	$scope.updateValorLiquido = function() {
		vm.lancamento.valor_liquido = vm.lancamento.valor_bruto + vm.lancamento.juros - vm.lancamento.desconto;
	}

	$scope.preencherCentroCusto = function() {
		if (vm.classificacaos && vm.lancamento.classificacao_id) {
			for(var i = 0; i < vm.classificacaos.length; i++){
				if(vm.classificacaos[i].id == vm.lancamento.classificacao_id && vm.classificacaos[i].centro_custo_id){
					vm.lancamento.centro_custo_id = vm.classificacaos[i].centro_custo_id;
					return;
				}
			}
		}
	}

	$scope.editContrato = function(index) {
		vm.editingContrato = angular.copy(vm.lancamento.contratos[index], {});
		vm.editingContratoIndex = index;
	}

	$scope.saveContrato = function() {
		if (vm.editingContratoIndex >= 0) {
			vm.lancamento.contratos[vm.editingContratoIndex] = vm.editingContrato;
			vm.editingContratoIndex = null;
		} else {
			vm.lancamento.contratos.push(vm.editingContrato);
		}
		vm.editingContrato = {};
		vm.contratoForm.$setPristine();
	}

	$scope.deleteContrato = function(index) {
		vm.lancamento.contratos.splice(index, 1);
	}

	$scope.somaContratos = function() {
		if (!vm.lancamento) {
			return 0;
		}

		var soma = 0;
		angular.forEach(vm.lancamento.contratos, function(contrato) {
			if (!contrato._destroy) {
				soma += parseFloat(contrato.valor);
			}
		});

		return soma;
	}

	$scope.preencherDataVencimento = function() {
		if (!vm.mostrou_alert_data_vencimento) {
	    	SweetAlert.swal({
	    		title: 'Atenção',
				text: 'Deseja preencher os campos Data de Vencimento e Data de Pagamento com a informação que está no campo Data de Emissão?',
				type: 'warning',
				cancelButtonText: 'Não',
				confirmButtonColor: "#46B999",
				confirmButtonText: "Sim",
				showCancelButton: true,
				closeOnConfirm: false,
	    	}, function(isConfirm) {
				vm.mostrou_alert_data_vencimento = true;
	    		if (isConfirm) {
	    			vm.lancamento.data_vencimento = vm.lancamento.data_emissao;
	    			vm.lancamento.data_pagamento = vm.lancamento.data_emissao;
	    			window.previousActiveElement = null;
	    			statusReadOnly();
	    			var teste = this;
	    		}
	    		SweetAlert.close();
	    	});
	    }
	}
	/**
	 * Controla a ativação/desativação do campo status
	 */
	$scope.statusReadOnly = function(newValue, oldValue){
		statusReadOnly((newValue ? newValue : ''), (oldValue ? oldValue : ''));
	}
	/**
	 * Necessário uso da função de forma interna
	 */
	function statusReadOnly(newValue, oldValue){
		if(newValue != oldValue){
			vm.statusReadOnly = (newValue ? true : false);
			if(vm.statusReadOnly){
				vm.lancamento.status = 'E';
				dtPagamentoRequired();
			}else{
				vm.lancamento.status = 'P';
				dtPagamentoRequired();
			}
		}
	}
	
	$scope.dtPagamentoRequired = function(){
		dtPagamentoRequired();
	}
	
	$scope.boolToStr = function(value) {
		return value ? 'Sim' : 'Não';
	}
	
	function dtPagamentoRequired(){
		if(vm.lancamento.status == 'E'){
			vm.hasPaid = true;
		}else{
			vm.hasPaid = false;
		}
	}
	
}]);
