app.controller('CargoEditCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Cargo', function($scope, $state, $stateParams, toastr, Cargo) {
	var vm = this;
	vm.cargoId = $stateParams.cargoId;

	getCargo();

	function getCargo() {
		Cargo.getCargo(vm.cargoId)
			.success(function(response) {
				vm.cargo = response;
			});
	}

	$scope.updateCargo = function() {
		Cargo.updateCargo(vm.cargo)
			.then(function() {
				toastr.success('Cargo alterado com sucesso.');
				$state.go('dashboard.cargos.list');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.cargoForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);