app.controller('CursoListCtrl', ['$scope', 'Curso', 'toastr', 'SweetAlert', '$state', function($scope, Curso, toastr, SweetAlert, $state) {
	$scope.cursos = [];
	$scope.cursosPerPage = 10;

	$scope.refreshCursos = function() {
        Curso.getCursos()
			.then(function(response) {
				$scope.cursos = response.data;
			});
	}

	$scope.refreshCursos();

	$scope.deleteCurso = function(id) {
		SweetAlert.swal({
			title: 'Tem certeza?',
			text: 'A remoção desta curso não pode ser desfeito.',
			type: 'warning',
			showCancelButton: true,
			cancelButtonText: 'Cancelar',
			confirmButtonColor: "#DD6B55",
			confirmButtonText: "Sim, apague!",
			closeOnConfirm: true
		}, function(isConfirm) {
			if (isConfirm) {
                Curso.deleteCurso(id)
					.then(function() {
						toastr.success('Curso removido com sucesso.');
						$scope.refreshCursos();
					})
					.catch(function() {
						toastr.error('Não foi possível remover esta curso porque ela está vinculada em outros registros.');
					});
			}
		});
	}
}]);