app.controller('RelacionamentoEditCtrl', ['$scope', 'relacionamento', 'Pessoa', 'TipoRelacionamento', '$uibModalInstance', function($scope, relacionamento, Pessoa, TipoRelacionamento, $uibModalInstance) {
	$scope.relacionamento = angular.copy(relacionamento, {});

	function getTiposRelacionamento() {
		TipoRelacionamento.getTiposRelacionamento()
			.then(function(response) {
				$scope.tipos = response.data;
			})
	}

	getTiposRelacionamento();

	$scope.refreshPessoas = function(search) {
		Pessoa.getPessoas(0, 10, search, 'id,razao_social')
			.then(function(response) {
				$scope.pessoas = response.data.items;
			});
	}

	$scope.save = function() {
		$uibModalInstance.close($scope.relacionamento);
	}

	$scope.cancel = function() {
		$uibModalInstance.dismiss('cancel');
	}
}]);