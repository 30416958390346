app.directive('confirmOnExit', ['$rootScope', function($rootScope) {
	return {
		require: '^form',
		link: function($scope, elem, attrs, ctrl) {
			var off = null;
			var listener = function(e) {	
				var msg = "Existem dados que não foram salvos ainda. Deseja realmente sair desta página?";
				e.returnValue = msg;
				return msg;
			};

			$scope.$watch(function() { return ctrl.$dirty; }, function(newVal) {
				if (newVal) {
					off = $rootScope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams, options) {
						if (!confirm('Existem dados que não foram salvos ainda. Deseja realmente sair desta página?')) {
							event.preventDefault();
						} else {
							off();
							window.removeEventListener('beforeunload', listener);
						}
					});
					window.addEventListener('beforeunload', listener);
				} else {
					if (off) {
						off();
						window.removeEventListener('beforeunload', listener);
					}
				}
			});
		}
	};
}]);