app.factory('Status', ['$http', function($http){
	return {
		getStatuses: function() {
			return $http.get('/api/statuses');
		},
		getStatus: function(id) {
			return $http.get('/api/statuses/' + id);	
		},
		insertStatus: function (statusData) {
			return $http.post('/api/statuses', statusData);
		},
		updateStatus: function (statusData) {
			return $http.put('/api/statuses/' + statusData.id, statusData);
		},
		deleteStatus: function(id) {
			return $http.delete('/api/statuses/' + id);
		}
	};
}])