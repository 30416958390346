app.factory('Classificacao', ['$http', function($http){
	return {
        getClassificacaos: function() {
			return $http.get('/api/classificacao');
		},
        getClassificacao: function(id) {
			return $http.get('/api/classificacao/' + id);
		},
        insertClassificacao: function (classificacaoData) {
			return $http.post('/api/classificacao', classificacaoData);
		},
        updateClassificacao: function (classificacaoData) {
			return $http.put('/api/classificacao/' + classificacaoData.id, classificacaoData);
		},
        deleteClassificacao: function(id) {
			return $http.delete('/api/classificacao/' + id);
		}
	};
}])