app.controller('SettingsCtrl', ['$scope', '$state', '$stateParams', 'toastr', 'Settings', 'Cep', function($scope, $state, $stateParams, toastr, Settings, Cep) {
	var vm = this;

	getSettings();

	function getSettings() {
		Settings.getSettings()
			.then(function(response) {
				vm.settings = response.data;
			});
	}

	$scope.buscarCep = function() {
		var cep = vm.settings.cep;
		
		if (!cep) {
			return false;
		};

		Cep.search(cep)
			.then(function(response) {
				var dados = response.data;
				if (dados.tipo == 1) {
					vm.settings.uf = dados.uf;
					vm.settings.cidade = dados.cidade;
					vm.settings.logradouro = dados.logradouro;
					vm.settings.bairro = dados.bairro;
				} else if (dados.tipo == 2) {
					vm.settings.uf = dados.uf;
					vm.settings.cidade = dados.cidade;
				} else {
					toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
				}
			}, function() {
				toastr.error('Verifique se o CEP foi digitado corretamente.', 'CEP não encontrado');
			});
	}

	$scope.updateSettings = function() {
		Settings.updateSettings(vm.settings)
			.then(function() {
				toastr.success('Configurações alteradas com sucesso.');
			})
			.catch(function(response) {
				vm.errorMessage = {};
				angular.forEach(response.data, function(messages, field) {
					vm.settingsForm[field].$setValidity('server', false);
					vm.errorMessage[field] = messages[0];
				});
			});
	}
}]);